import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import Modal from 'react-bootstrap/Modal';
import {FaSave, FaTimes} from "react-icons/fa";
import {getCookie} from "../func/Func";
import toast from "react-hot-toast";

export const AddParentGroup = (props) => {
    const {t} = useTranslation();
    const {
        showAddGroupModal,
        setShowAddGroupModal,
        onShow,
        onHide,
        theme,
        groups,
        setGroups,
        addGroup,
        parentGroupID,
        parentGroup,
        user,

    } = props;

    const [groupName, setGroupName] = useState('');

    const groupNameChanged = (e) => {
        setGroupName(e.target.value);
    }


    const saveGroup = () => {
        if (!groupName) {
            toast.error(t('Please enter a group name'));
            return;
        }
        let newGroup = {
            name: groupName,
            company: user.company_id,
            parentGroup: parentGroupID ? parentGroupID : null,
        }
        addGroup(newGroup);
    }

    return (
        <>
            {showAddGroupModal && <Modal
            size={'md'}
            show={showAddGroupModal}
            onHide={onHide}
            // centered={true}
            // className={`modal-blur`}
            className={`modal-custom-backdrop-${theme}`}
            backdrop={"static"}
            // dialogClassName={`box-shadow-${theme}`}

        >
            <Modal.Body className={`box-shadow-${theme} rounded-3`}>
                <div className="row">
                    <div className="d-flex col-lg-12">

                        <h3>
                            {t("Add Parent Group")}
                            {/*<span className={"badge bg-primary-lt ms-2"}>{selectedFamily?.members?.length} Member(s)</span>*/}
                        </h3>
                        <FaTimes size={20} className={"ms-auto cursor-pointer"} onClick={onHide}/>
                    </div>
                </div>
                <div className="pt-0">
                    <form action="#" className={"row"}>
                        <div className="col-lg-6 mb-2">
                            <div className="form-group">
                                <label htmlFor="name">{t("Group Name")}</label>
                                <input onInput={groupNameChanged} value={groupName ? groupName : ''} required={true}
                                       type="text" className="form-control" id="name" placeholder={t("Group Name")}/>
                            </div>
                        </div>


                        <div className="col-lg-12">
                            <button onClick={saveGroup} type={'button'}
                                    className="btn btn-primary  w-100">
                                <FaSave className={"me-2"}/>
                                {t("Save")}
                            </button>
                        </div>
                    </form>
                </div>

            </Modal.Body>

            </Modal>}
        </>
    );
}

