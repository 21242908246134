import React, {useEffect, useState} from 'react';


const getKey = (dictKey, option) => {
  let key = "";
  let keys = dictKey.split(".");
  let temp = option;
  keys.forEach((key) => {
    if (temp) {
      temp = temp[key];
    }
  });
  key = temp;
  return key;
};

export function useSearch() {
  const [results, setResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  return {results, setSearchTerm, searchTerm, setResults};
}

export default function SearchField(props) {
  const {results, setSearchTerm, searchTerm, placeholder, setResults, dictKey, options} = props;

  useEffect(() => {
    setResults(options);
  },[])

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setResults(options.filter(result => {
      return getKey(dictKey, result).toLowerCase().includes(e.target.value.toLowerCase());
    }))
  }

  return (
    <>
      <input type="text" value={searchTerm} onInput={handleSearch} className={"form-control"} placeholder={placeholder}/>
    </>
  )

}