// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-overlay-light {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    transition-duration: 100ms;
}

.card-overlay-dark {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    transition-duration: 100ms;
}


.card-container {
    position: relative;
    transition-duration: 100ms;
}

.box-shadow-dark {
    box-shadow: 0 0 14px -1px rgba(255,255,255,0.5);
}

.box-shadow-light {
    box-shadow: 0 0 14px -1px rgba(0,0,0,0.5);
}

.animate-100 {
    transition-duration: 100ms !important;
}

.modal-custom-backdrop-light {
    background: rgba(255, 255, 255, 0.8);
}

.modal-custom-backdrop-dark {
    background: rgba(0, 0, 0, 0.8);
}

input:invalid {
    border: 1px solid red;
}


.w-fit-content {
    width: -moz-fit-content !important;
    width: fit-content !important;
}`, "",{"version":3,"sources":["webpack://./src/Custom.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,oCAAoC;IACpC,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;IACX,0BAA0B;AAC9B;;AAEA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,8BAA8B;IAC9B,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;IACX,0BAA0B;AAC9B;;;AAGA;IACI,kBAAkB;IAClB,0BAA0B;AAC9B;;AAEA;IACI,+CAA+C;AACnD;;AAEA;IACI,yCAAyC;AAC7C;;AAEA;IACI,qCAAqC;AACzC;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI,qBAAqB;AACzB;;;AAGA;IACI,kCAA6B;IAA7B,6BAA6B;AACjC","sourcesContent":[".card-overlay-light {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background: rgba(255, 255, 255, 0.6);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 10;\n    transition-duration: 100ms;\n}\n\n.card-overlay-dark {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background: rgba(0, 0, 0, 0.3);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 10;\n    transition-duration: 100ms;\n}\n\n\n.card-container {\n    position: relative;\n    transition-duration: 100ms;\n}\n\n.box-shadow-dark {\n    box-shadow: 0 0 14px -1px rgba(255,255,255,0.5);\n}\n\n.box-shadow-light {\n    box-shadow: 0 0 14px -1px rgba(0,0,0,0.5);\n}\n\n.animate-100 {\n    transition-duration: 100ms !important;\n}\n\n.modal-custom-backdrop-light {\n    background: rgba(255, 255, 255, 0.8);\n}\n\n.modal-custom-backdrop-dark {\n    background: rgba(0, 0, 0, 0.8);\n}\n\ninput:invalid {\n    border: 1px solid red;\n}\n\n\n.w-fit-content {\n    width: fit-content !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
