import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useAuth} from "../hooks/useAuth";
import {useTheme} from "../hooks/useTheme";
import toast from "react-hot-toast";
import Groups from "./Groups";
import {Button} from "@progress/kendo-react-buttons";


const darkBg = {
    backgroundColor: '#151f2c',
}

export const OtherSettings = (props) => {
    const {t} = useTranslation();
    const {user} = useAuth();
    const {theme} = useTheme();
    const [showGroupModal, setShowGroupModal] = useState(false);


    const handleShowGroupsModal = () => {
        setShowGroupModal(true);
    }

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-6">
                        <div className="card">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card-body">
                                        <div className="form-group">
                                            <Groups
                                                showGroupModal={showGroupModal}
                                                setShowGroupModal={setShowGroupModal}
                                                handleShowGroupsModal={handleShowGroupsModal}
                                                user={user}
                                                theme={theme}

                                            />
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </>
    )
}