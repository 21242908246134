import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import Modal from 'react-bootstrap/Modal';
import {FaSave, FaTimes, FaTrash, FaUser} from "react-icons/fa";
import toast from "react-hot-toast";

import {Upload} from "@progress/kendo-react-upload";
import {LocalizationProvider,loadMessages}  from "@progress/kendo-react-intl";
import {Button} from "@progress/kendo-react-buttons";
import "@progress/kendo-theme-default/dist/all.css";
import "./Styles/membermodal.css";
import {RadioGroup} from "@progress/kendo-react-inputs";
import {AddVisitModal} from "./AddVisitModal";
import {usePermissions} from "../hooks/PermissionContext";
import {getCookie} from "../func/Func";

export const MemberModal = (props) => {
    const {t} = useTranslation();
    const permissions = usePermissions();
    const languages = [
        {
            label: "Afrikaans",
            value: "af",
        },
        {
            label: "English",
            value: "en",
        },
    ];
    const [editedMember, setEditedMember] = useState({
        FullNames: '',
        Names: '',
        Surname: '',
        BirthDate: '',
        Initials: '',
        IdNo: '',
        notesByType: {},
        selectedNoteType: 'General',
        title: '',
        picture: '',
        pictureUrl: '',
        profession: 'null',
        member_email: '',
        phone_tel: '',
        phone_cell: '',
        employer: '',
        language: '',
    });
    const {
        show,
        // onShow,
        onHide,
        selectedMember,
        theme,
        memberVisits,
        saveEditedMember,
        selectedFamilyChanged,
        setSelectedMember,
        selectedMemberChanged,
        titles,
        professions,
        // setTitles,
        // setProfessions,
        noteTypes,
        user,
        getFamily,
        // setNoteTypes,
    } = props;

    const [files, setFiles] = React.useState([]);
    const [checked, setChecked] = React.useState(languages[0].value);
    const [disabled, setDisabled] = React.useState(true);
    const [addVisitModal, setAddVisitModal] = React.useState(false);
    const [showVisitNotesIndex, setShowVisitNotesIndex] = React.useState(null);
    const [showVisitNotes, setShowVisitNotes] = React.useState(false);
    const handleChange = (e) => {
        setChecked(e.value);
        setEditedMember({...editedMember, language: e.value});
    };
    const handleClick = () => {
        setDisabled(!disabled);
    };
    const onAdd = (event) => {
        const file = event.affectedFiles[0].getRawFile();
        setEditedMember({...editedMember, picture: file});
        setFiles(event.newState);
    };

    useEffect(() => {
        if (selectedMember) {
            const updatedMember = {...selectedMember, notesByType: {}, selectedNoteType: 'General'};
            if (selectedMember.notes) {
                updatedMember.notesByType = selectedMember.notes.reduce((acc, note) => {
                    acc[note.type.name] = note.note_text;
                    return acc;
                }, {});
            }
            if (selectedMember.picture && typeof selectedMember.picture === 'string') {
                updatedMember.pictureUrl = selectedMember.picture;
                updatedMember.picture = null;
            }
            setEditedMember(updatedMember);
            if (selectedMember.language) {
                setChecked(selectedMember.language);
            }
        } else {
            setEditedMember({
                FullNames: '',
                Names: '',
                Surname: '',
                BirthDate: '',
                Initials: '',
                IdNo: '',
                notesByType: {},
                selectedNoteType: 'General',
                title: '',
                picture: null,
                pictureUrl: '',
                profession: 'null',
                member_email: '',
                phone_tel: '',
                phone_cell: '',
                employer: '',
            });
        }
        setShowVisitNotesIndex(null);
    }, [selectedMember], [memberVisits]);


    const memberValueChanged = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        if ((name === 'title' || name === 'profession') && !isNaN(value)) {
            value = Number(value);
        }

        setEditedMember({...editedMember, [name]: value});
    };

    const noteContentChanged = (e) => {
        const {value} = e.target;
        if (editedMember.selectedNoteType) {
            setEditedMember({
                ...editedMember,
                notesByType: {
                    ...editedMember.notesByType,
                    [editedMember.selectedNoteType]: value
                }
            });
        } else {
            toast.error(t('Please select a note type first'));
        }
    };

    const isMemberUnchanged = () => {
        return JSON.stringify(editedMember) === JSON.stringify(selectedMember);
    }


    const saveMemberClicked = () => {
        let family_id = selectedMember?.family_id;
        const memberData = {...editedMember, notes: JSON.stringify(editedMember.notesByType)};
        saveEditedMember(memberData).then(data => {
            if (data?.success) {
                toast.success(t('Member saved successfully'));
                selectedFamilyChanged(family_id);
                onHide();
                setSelectedMember(null);
            } else {
                toast.error(t('Failed to save member'));
            }
        });
    }

    const showAddVisitModal = () => {
        setAddVisitModal(true);
    }

    const onHideAddVisitModal = () => {
        setAddVisitModal(false);
        selectedMemberChanged(selectedMember);
    }

    const showNotes = (index) => {
        setShowVisitNotesIndex(index);
    }

    // const onHideVisitNotesModal = () => {
    //     setShowVisitNotesModal(false);
    // }
     loadMessages(
    {
      upload: {
        dropFilesHere: "Drop photo here to upload",
        select: "Upload Photo",
      },
    },
    "myCustomMessages"
  );

    const handleDeleteMember = (member_id) => {
        let family_id = selectedMember?.family_id
        if (window.confirm("Are you sure you want to delete this member?")) {
           deleteMember_api(member_id).then(data => {
                if (data?.success) {
                    toast.success(t('Member deleted successfully'));
                    selectedFamilyChanged(family_id);
                    onHide();
                    setSelectedMember(null);
                } else {
                    toast.error(t('Failed to delete member'));
                }
            }
            );
           }
    }

    const deleteMember_api = async (member_id) => {
        const response = await fetch(`/apiv2/delete_member/${member_id}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                "X-CSRFToken": getCookie("csrftoken"),
                "member_id": member_id
    }
        });
        return await response.json();
    }

    return (
        <>
            <AddVisitModal
                showVisit={addVisitModal}
                onHideVisit={onHideAddVisitModal}
                setAddVisitModal={setAddVisitModal}
                addVisitModal={addVisitModal}
                member_id={selectedMember?.id}
                theme={theme}
                selectedMember={selectedMember}
                user={user}
            />
            <Modal
                size={'xl'}
                show={show}
                onHide={onHide}
                centered={true}
                className={`modal-custom-backdrop-${theme}`}
                backdrop={"static"}
            >
                <Modal.Body style={{overflow: "auto"}} className={`box-shadow-${theme} rounded-3`}>
                    <div className="row">
                        <div className="d-flex col-lg-12">
                            <h3>{t("Member View")}</h3>
                            <FaTimes size={20} className={"ms-auto cursor-pointer"} onClick={onHide}/>
                        </div>
                    </div>
                    {permissions?.permissions?.includes("delete_members") &&
                        <div className="row">
                        <div className="d-flex col-lg-12" style={{padding: "10px"}}>
                            <Button className="bg-danger-lt"
                                    onClick={(event) => {
                                        handleDeleteMember(selectedMember.id)
                                    }}
                            >
                                <FaTrash/> {t("Delete this member")}
                            </Button>
                        </div>
                    </div>}
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="card">
                                <h4 className={"text-center p-1 bg-primary-lt mb-0"}>{t("Information")}</h4>
                                <div className="row p-3" style={{maxWidth: "100%", overflowX: "auto"}}>
                                    <div className="col-lg-12">
                                        <div className="form-group pb-2 d-flex align-items-center">
                                            {editedMember.picture ? (
                                                <>
                                                    <img
                                                        style={{
                                                            borderRadius: '50%',
                                                            width: '100%',
                                                            maxWidth: '100px',
                                                            height: 'auto',
                                                            objectFit: 'cover',
                                                            objectPosition: 'center',
                                                            marginBottom: '10px',
                                                            boxShadow: '0 0 10px rgba(0,0,0,0.1)'
                                                        }}
                                                        src={URL.createObjectURL(editedMember.picture)}
                                                        alt="Member"
                                                    />
                                                </>
                                            ) : (
                                                editedMember.pictureUrl ? (
                                                    <img
                                                        style={{
                                                            borderRadius: '50%',
                                                            width: '100%',
                                                            maxWidth: '100px',
                                                            height: 'auto',
                                                            objectFit: 'cover',
                                                            objectPosition: 'center',
                                                            marginBottom: '10px',
                                                            boxShadow: '0 0 10px rgba(0,0,0,0.1)'
                                                        }}
                                                        src={editedMember.pictureUrl}
                                                        alt={t("Member")}
                                                        onError={(e) => {
                                                            e.target.onerror = null;
                                                            e.target.src = '';
                                                        }}
                                                    />
                                                ) : (
                                                    <FaUser style={{
                                                        borderRadius: '50%',
                                                        width: '100%',
                                                        maxWidth: '100px',
                                                        height: 'auto',
                                                        marginBottom: '10px',
                                                        boxShadow: '0 0 10px rgba(0,0,0,0.1)',
                                                        color: 'rgba(124,116,200,0.1)'
                                                    }}/>
                                                )
                                            )}
                                            <div className="ms-3 flex-grow-2">
                                                <label>{t("Title")}</label>
                                                <select onChange={memberValueChanged}
                                                        name={"title"}
                                                        className="form-control"
                                                        disabled={!permissions?.permissions?.includes("modify_members")}
                                                        value={editedMember?.title || ''}>
                                                    {titles?.map((title, Index) => {
                                                        return (
                                                            <option key={Index} value={title.id}>{title.name}</option>
                                                        )
                                                    })}
                                                </select>

                                            </div>

                                            <div className="ms-3 flex-grow-1">
                                                <label>{t("Full Names")}</label>
                                                <input
                                                    readOnly={!permissions?.permissions?.includes("modify_members")}
                                                    onInput={memberValueChanged}
                                                    type="text" name={"FullNames"}
                                                    className="form-control" value={editedMember?.FullNames || ''}/>
                                            </div>
                                            <div className="ms-3 flex-grow-1">
                                                <label>{t("Surname")}</label>
                                                <input onInput={memberValueChanged}
                                                       readOnly={!permissions?.permissions?.includes("modify_members")}
                                                       type="text" name={"Surname"}
                                                       className="form-control" value={editedMember?.Surname || ''}/>
                                            </div>


                                        </div>

                                    </div>

                                    {/*<div className="col-lg-6">*/}
                                    <div className="d-flex align-items-center">
                                        {permissions?.permissions?.includes("modify_members") &&
                                            <div className="ms-sm-1 flex-grow-1">
                                                <LocalizationProvider language="myCustomMessages">
                                                <Upload
                                                    defaultFiles={[]}
                                                    withCredentials={false}
                                                    files={files}
                                                    onAdd={onAdd}
                                                />
                                                </LocalizationProvider>
                                            </div>}
                                        <div className="ms-3 flex-grow-1">
                                            <label>{t("Names")}</label>
                                            <input
                                                readOnly={!permissions?.permissions?.includes("modify_members")}
                                                onInput={memberValueChanged} type="text" name={"Names"}
                                                className="form-control" value={editedMember?.Names || ''}/>
                                        </div>
                                        <div className="ms-3 flex-grow-1">
                                            <label>{t("Initials")}</label>
                                            <input
                                                readOnly={!permissions?.permissions?.includes("modify_members")}
                                                onInput={memberValueChanged}
                                                type="text" name={"Initials"}
                                                className="form-control"
                                                value={editedMember?.Initials || ''}/>
                                        </div>

                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group pb-2 d-flex align-items-center">
                                            <div className=" flex-grow-1">
                                                <label>{t("Date of Birth")}</label>
                                                <input
                                                    readOnly={!permissions?.permissions?.includes("modify_members")}
                                                    onInput={memberValueChanged}
                                                    type="date"
                                                    name={"BirthDate"}
                                                    className="form-control"
                                                    // defaultValue={editedMember?.BirthDate}
                                                    value={editedMember?.BirthDate ? editedMember?.BirthDate : ""}
                                                />
                                            </div>
                                            <div className=" flex-grow-1 ms-3">
                                                <label>{t("ID Number")}</label>
                                                <input
                                                    readOnly={!permissions?.permissions?.includes("modify_members")}
                                                    onInput={memberValueChanged}
                                                    type="text" name={"IdNo"}
                                                    className="form-control" value={editedMember?.IdNo || ''}/>
                                            </div>
                                            <div className=" flex-grow-1 ms-3">
                                                <label>{t("Email")}</label>
                                                <input
                                                    readOnly={!permissions?.permissions?.includes("modify_members")}
                                                    onInput={memberValueChanged}
                                                    type="text" name={"email"}
                                                    className="form-control" value={editedMember?.email || ''}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group pb-2 d-flex align-items-center">
                                            <div className=" flex-grow-1">
                                                <label>{t("Home Telephone")}</label>
                                                <input
                                                    readOnly={!permissions?.permissions?.includes("modify_members")}
                                                    onInput={memberValueChanged}
                                                    type="text" name={"phone_tel"}
                                                    className="form-control" value={editedMember?.phone_tel || ''}/>
                                            </div>
                                            <div className=" flex-grow-1 ms-3">
                                                <label>{t("Cellphone")}</label>
                                                <input
                                                    readOnly={!permissions?.permissions?.includes("modify_members")}
                                                    onInput={memberValueChanged}
                                                    type="text" name={"phone_cell"}
                                                    className="form-control" value={editedMember?.phone_cell || ''}/>
                                            </div>
                                            <div className="ms-3 flex-grow-1">
                                                <label>{t("Profession")}</label>
                                                <select onChange={memberValueChanged}
                                                        name={"profession"}
                                                        className="form-control"
                                                        disabled={!permissions?.permissions?.includes("modify_members")}
                                                        value={editedMember?.profession || ''}>
                                                    {professions?.map((profession, Index) => {
                                                        return (
                                                            <option key={Index}
                                                                    value={profession?.name}>{profession?.name || ''}</option>
                                                        )
                                                    })}
                                                </select>

                                            </div>
                                            <div className="ms-3 flex-grow-1">
                                                <label>{t("Employer")}</label>
                                                <input
                                                    readOnly={!permissions?.permissions?.includes("modify_members")}
                                                    onInput={memberValueChanged} type="text" name={"employer"}
                                                    className="form-control" value={editedMember?.employer || ''}/>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group pb-2 d-flex align-items-center">
                                            {/*check if disabled is true then show RadioGroup*/}
                                            {!disabled ? (<div className=" flex-grow-1">
                                                <RadioGroup
                                                    data={languages}
                                                    disabled={disabled}
                                                    value={checked}
                                                    onChange={handleChange}
                                                />
                                            </div>) : null}
                                            <div className=" flex-grow-1 ms-3">
                                                {t('Preferred Language')}: <strong> {checked === 'en' ? (t('English')) : (t('Afrikaans'))}</strong>
                                            </div>
                                            {permissions?.permissions?.includes("modify_member") &&
                                                <div className=" flex-grow-1 ms-3">
                                                    <Button type="button" onClick={handleClick}>
                                                        {disabled ? (t('Change Preferred Language')) : (t('Set Language'))}
                                                    </Button>
                                                </div>}
                                        </div>
                                    </div>
                                    {permissions?.permissions?.includes("view_notes") &&
                                        <div className="col-lg-12">
                                            <div className="form-group pb-2">
                                                <label>{t("Select Note Type:")} </label>
                                                <select onChange={memberValueChanged} name={"selectedNoteType"}
                                                        className="form-control"
                                                        value={editedMember.selectedNoteType || ''}>
                                                    {noteTypes?.map((notetype, Index) => (
                                                        <option key={Index} value={notetype.id}>{notetype.name}</option>
                                                    ))}
                                                </select>

                                                {permissions?.permissions?.includes("modify_notes") ?
                                                    (<textarea onInput={noteContentChanged} style={{resize: 'none'}}
                                                               name={"notes"} rows={3} className="form-control"
                                                               value={editedMember.selectedNoteType === 'General'
                                                                   ? editedMember.notesByType['General'] || ''
                                                                   : editedMember.notesByType[editedMember.selectedNoteType] || ''}/>) :
                                                    (<textarea readOnly style={{resize: 'none'}}
                                                               name={"notes"} rows={3} className="form-control"
                                                               value={editedMember.selectedNoteType === 'General'
                                                                   ? editedMember.notesByType['General'] || ''
                                                                   : editedMember.notesByType[editedMember.selectedNoteType] || ''}/>)
                                                }
                                            </div>
                                        </div>}
                                    <div className="col-lg-12">
                                        <button onClick={saveMemberClicked}
                                                className={`btn btn-${isMemberUnchanged() ? 'secondary' : 'primary'} w-100`}>
                                            <FaSave className={"me-2"}/>
                                            {t("Save")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="card">
                                <h4 className={"text-center p-1 bg-primary-lt mb-0"}>{t("Visits")}</h4>
                                {permissions?.permissions?.includes("add_visits") &&
                                    <div className="d-flex justify-content-end" style={{margin: 10}}>
                                        <Button
                                            onClick={showAddVisitModal}
                                            themeColor={"base"}
                                        >{t("Add Visit")}</Button>

                                    </div>}
                                <div className="row">
                                    {memberVisits
                                            ?.filter(visit => visit.visited_user.id === editedMember.id).length === 0 &&
                                        (
                                            <div className="col-12">
                                                <div className="card card-table pt-3">
                                                    <h4 className={"fw-bold text-center text-muted"}>{t("No Visits")}</h4>
                                                </div>
                                            </div>
                                        )}
                                    {memberVisits
                                        ?.filter(visit => visit.visited_user.id === editedMember.id)
                                        .map((visit, visitIndex) => {
                                            return (
                                                <div className="col-12" key={visitIndex}>
                                                    <div className={"m-2"}>
                                                        <div className="card card-table pt-3"
                                                             style={{overflow: "auto"}}>
                                                            <table className="table table-borderless table-striped">
                                                                <tr>
                                                                    <td className={"fw-bold"}>{t("Visit Date")}</td>
                                                                    <td>
                                                                    <span
                                                                        className={"badge bg-info-lt p-1"}>{visit?.dt_created}</span>
                                                                    </td>

                                                                    <td className={"fw-bold"}>{t("Visited by")}</td>
                                                                    <td>
                                                                    <span
                                                                        className={"badge bg-warning-lt p-1"}> {visit?.visiting_user?.username}</span>
                                                                    </td>
                                                                </tr>
                                                                {permissions?.permissions?.includes("view_visit_notes") &&
                                                                    <tr>
                                                                        <td className={"fw-bold"}>{t("Visit Reason")}</td>
                                                                        <td>{visit?.reason?.name}</td>
                                                                        {permissions?.permissions?.includes("view_notes") &&
                                                                            <td style={{
                                                                                position: "relative",
                                                                                float: "right"
                                                                            }}>{showVisitNotesIndex !== visitIndex ? (
                                                                                <Button
                                                                                    fillMode="flat"
                                                                                    onClick={() => showNotes(visitIndex)}
                                                                                >
                                                                                    {t("View Notes")}
                                                                                </Button>
                                                                            ) : (
                                                                                <Button
                                                                                    fillMode="flat"
                                                                                    onClick={() => showNotes(null)}
                                                                                >
                                                                                    {t("Hide Notes")}
                                                                                </Button>
                                                                            )}</td>}
                                                                    </tr>}

                                                                {showVisitNotesIndex === visitIndex ? (
                                                                    <tr>
                                                                        <td className={"fw-bold"}>{t("Visit Notes")}
                                                                        </td>
                                                                        <td>{visit?.notes}</td>
                                                                    </tr>
                                                                ) : (
                                                                    <tr>
                                                                        <td></td>
                                                                    </tr>
                                                                )
                                                                }
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}