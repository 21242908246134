import React from "react";
import { Upload } from "@progress/kendo-react-upload";
import { LocalizationProvider } from "@progress/kendo-react-intl";

const LogoUpload = (fieldRenderProps) => {
    const { value, onChange, onBlur, onFocus, ...others } = fieldRenderProps;

    const onAdd = (event) => {
        const file = event.affectedFiles[0].getRawFile();
        onChange({ value: file });
        others.setFiles(event.newState);
        others.setCompanyDetails(prevDetails => ({ ...prevDetails, logo: file }));
        others.setIsChanged(true);
    };

    const onRemove = () => {
        others.setFiles([]);
        others.setCompanyDetails(prevDetails => ({ ...prevDetails, logo: null }));
        others.setIsChanged(true);
    };

    return (
        <div className="k-form-field-wrap">
            <LocalizationProvider language="myCustomMessages">
                <Upload
                    batch={false}
                    multiple={false}
                    defaultFiles={[]}
                    withCredentials={false}
                    files={others.files[0]}
                    onAdd={onAdd}
                    onError={others.onError}
                    onRemove={onRemove}
                    saveUrl={"https://demos.telerik.com/kendo-ui/service-v4/upload/save"}
                    removeUrl={"https://demos.telerik.com/kendo-ui/service-v4/upload/remove"}
                />
            </LocalizationProvider>
        </div>
    );
};

export default LogoUpload;