export const getCookie = (name) => {
    // Move this to the useAuth hook
    let cookieValue = null;
    if (document.cookie && document.cookie !== "") {
        const cookies = document.cookie.split(";");
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === name + "=") {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
};


export const toggleSideBar = () => {
    // Move this to the useWebsocket hook
    var sidebar = document.querySelector(".navbar-toggler");
    var navbar_menu = document.querySelector("#navbar-menu");
    if (sidebar.classList.contains("collapsed")) {
        sidebar.classList.remove("collapsed");
        sidebar.setAttribute("aria-expanded", "true");
        if(!navbar_menu.classList.contains("show")) {
            navbar_menu.classList.add("show");
        }
    } else {
        sidebar.classList.add("collapsed");
        sidebar.setAttribute("aria-expanded", "false");
        if(navbar_menu.classList.contains("show")) {
            navbar_menu.classList.remove("show");
        }
    }
}


