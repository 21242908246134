import {createContext, useContext, useMemo, useState} from "react";
// import {useLocalStorage} from "@/hooks/use-local-storage";
import {useLocalStorage} from "../components/useLocalStorage";
import {getCookie} from "../func/Func";

const AuthContext = createContext();

export const AuthProvider = ({children}) => {
    const [user, setUser] = useLocalStorage("user", null);
    const [permissions, setPermissions] = useState([]);

    const auth = async () => {
        try {
            const response = await fetch(`/apiv2/user/`);
            const data = await response.json();
            if (data?.is_authenticated === false) {
                setUser(null);
                setPermissions([]);
                return false;
            } else {
                setUser(data?.user);
                return true;
            }

            // if (!response.json()) {
            //   setUser(null);
            // }
        } catch (error) {
            console.error(error);
        }
    };

    const fetchPermissions = async () => {
        try {
            const response = await fetch(`/apiv2/userpermissions/`);
            const data = await response.json();
            setPermissions(data?.permissions || []);
        } catch (error) {
            console.error("Error fetching permissions:", error);
        }
    };

    const signin = async (data) => {
        const username = data.username;
        const password = data.password;


        const response = await fetch(`/apiv2/login/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken'),
            },
            body: JSON.stringify({username, password}),
        });

        const jsondata = await response.json();
        if (jsondata?.success) {
            setUser(jsondata?.user);
            return true;
        } else {
            return false;
        }

        // e.preventDefault();
        // fetch('/api/login/', {
        // fetch('/apiv2/login/', {
        //   method: 'POST',
        //   headers: {
        //     'Content-Type': 'application/json',
        //     'X-CSRFToken': getCookie('csrftoken'),
        //   },
        //   body: JSON.stringify({username, password}),
        // })
        //   .then(response => response.json())
        //   .then(data => {
        //     if (data?.success) {
        //       setUser(data?.user);
        //       return true;
        //     }else{
        //       return false;
        //     }
        //   })
        //   .catch(err => {
        //     console.log("/api/login/", err);
        //     return false;
        //   })

        // try {
        //   const response = await fetch(`http://localhost:8000/api/auth/signin`, {
        //     method: "POST",
        //     headers: {
        //       "Content-Type": "application/json",
        //     },
        //     body: JSON.stringify({
        //       username,
        //       password,
        //     }),
        //   });
        //
        //   if (response.ok) {
        //     const data = await response.json();
        //     setUser({username: username, token: data.token});
        //     return {
        //       success: true,
        //       data: data,
        //     };
        //   } else {
        //     console.error("Sign in failed");
        //     return {
        //       success: false,
        //       data: null,
        //     };
        //   }
        // } catch (error) {
        //   console.error(error);
        // }
    };

    const signup = async (data) => {
        const email = data.email;
        const username = data.username;
        const password = data.password;

        try {
            const response = await fetch(`http://localhost:8000/api/auth/signup`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    email,
                    username,
                    password,
                }),
            });

            if (response.ok) {
                const data = await response.json();
                setUser({username: username, token: data.token});
                await fetchPermissions();
                return {
                    success: true,
                    data: data,
                };
            } else {
                console.error("Sign up failed");
                return {
                    success: false,
                    data: null,
                };
            }
        } catch (error) {
            console.error(error);
            return {
                success: false,
                error: error,
            };
        }
    };

    const signout = async () => {
        setUser(null);
        setPermissions([]);
        try {
            const response = await fetch(`/apiv2/logout/`);
            const data = await response.json();
            // window.location.reload(true);
            return data?.success;
        } catch (error) {
            console.error(error);
            return false;
        }
    };

const changePassword = async (data) => {
    const current_password = data.current_password;
    const new_password = data.new_password;
    const confirm_password = data.confirm_password;
    try {
        const response = await fetch(`/apiv2/change_password/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken'),
            },
            body: JSON.stringify({
                current_password,
                new_password,
                confirm_password,
            }),
        });
        const data = await response.json();
        if (data?.success) {
            await auth();
            return true;
        }
    } catch (error) {
        console.error(error);
        return false;
    }
};
    const value = useMemo(
        () => ({
            user,
            setUser,
            auth,
            signin,
            signup,
            signout,
            fetchPermissions,
            permissions,
            changePassword,
        }),
        [user, permissions]
    );

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    return useContext(AuthContext);
};