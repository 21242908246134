import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {FaUserPlus, FaSearch} from "react-icons/fa";
import {SearchModal} from "../modals/SearchModal";
import {Paginator} from "./Paginator";
import TableHeadColumn from "./TableHeadColumn";
import {Grid, GridColumn as Column} from "@progress/kendo-react-grid";
import {groupBy} from "@progress/kendo-data-query";
import "@progress/kendo-theme-default/dist/all.css";
import {usePermissions} from "../hooks/PermissionContext";

export const MemberList = (props) => {
    const {t} = useTranslation();
    const permissions = usePermissions();
    const {
        showSearchModal,
        handleShowSearchModal,
        handleCloseSearchModal,
        togglePrivacyMode,
        rowRightClick,
        rowClick,
        handleCloseContextMenu,
        members,
        page,
        setPage,
        totalPages,
        handleShowAddFamilyModal,
        searchChanged,
        search,
        theme,
        totalMembersList,

        // selectedMemberChanged
    } = props;
    const [filter, setFilter] = useState(undefined)
    const MemberTypeCell = (props) => {
        const {dataItem} = props;
        return (
            <td>
                {dataItem?.HeadNo === 1 ? t("Head Member") : dataItem?.SpouseNo === 1 ? t("Spouse") : t("Child")}
            </td>
        )

    }
    const HomeAddressCell = (props) => {
        const {dataItem} = props;
        return (
            <td>
                {dataItem?.home_address?.street_address}, {dataItem?.home_address?.suburb}
            </td>
        )
    }

    return (
        <>
            <SearchModal
                show={showSearchModal}
                handleCloseSearchModal={handleCloseSearchModal}
                handleShowSearchModal={handleShowSearchModal}
                searchChanged={searchChanged}
                memberSearch={search}
                theme={theme}
            />
            <div className="row">
                <div className="col-lg-12 p-2 px-4">
                    <div className="d-flex justify-content-between">
                        <div>
                            <h2>{t("Members")}</h2>
                        </div>
                        <div className={"d-flex align-items-center"}>

                            {permissions?.permissions?.includes('modify_members') &&
                            <button onClick={handleShowAddFamilyModal} className="btn btn-primary me-2">
                                <FaUserPlus/>
                                <span className={"d-none d-lg-block ms-2"}>{t("Add Family")}</span>
                            </button>}
                            <div className="input-icon d-none d-lg-block">
                                <input type="text" onInput={searchChanged} value={search} className="form-control"
                                       placeholder={t("Search…")}/>
                                <span className="input-icon-addon">
                                      <FaSearch/>
                                  </span>
                            </div>
                        </div>
                    </div>
                </div>
                        {/*<div>*/}
                        {/*    <Grid data={members}>*/}
                        {/*        <Column field="UniqueLidNo" title="Lid #"/>*/}
                        {/*        <Column field="Names" title="Names"/>*/}
                        {/*        <Column field="FullNames" title="Full Names"/>*/}
                        {/*        <Column field="Surname" title="Surname"/>*/}
                        {/*        <Column field="HeadNo" title="Family Information" cell={MemberTypeCell}/>*/}
                        {/*        <Column field="IdNo" title="ID"/>*/}
                        {/*        <Column field="BirthDate" title="DOB"/>*/}
                        {/*        <Column field="age" title="Age"/>*/}
                        {/*        <Column field="phone_cell" title="Cellphone"/>*/}
                        {/*        <Column field="phone_tel" title="Home Telephone"/>*/}
                        {/*        <Column field="type" title="Member Type"/>*/}
                        {/*        <Column field="profession" title="Profession"/>*/}
                        {/*        <Column field="home_address" title="Home Address" cell={HomeAddressCell}/>*/}
                        {/*        <Column field="date_updated" title="Last Update"/>*/}
                        {/*    </Grid>*/}
                        {/*</div>*/}

                        <div className="d-lg-none">
                            <button onClick={handleShowSearchModal} className="btn btn-primary">
                                <FaSearch/>
                            </button>
                        </div>


                        <div>
                            <div className="col-lg-12 table-responsive table-nowrap">
                                <table className="table table-hover table-striped">
                                    <thead>
                                    <tr>
                                        {/*<TableHeadColumn text={"ID"} keyName={"id"} {...props}/>*/}
                                        <TableHeadColumn text={t("Lid #")} keyName={"UniqueLidNo"} {...props}/>
                                        {/*<TableHeadColumn text={"Seq #"} keyName={"SeqNo"} {...props}/>*/}
                                        <TableHeadColumn text={t("Names")} keyName={"Names"} {...props}/>
                                        <TableHeadColumn text={t("Full Names")} keyName={"FullNames"} {...props}/>
                                        <TableHeadColumn text={t("Last Name")} keyName={"Surname"} {...props}/>
                                        <TableHeadColumn text={t("Family Information")} keyName={"Family Information"} {...props}/>
                                        <TableHeadColumn text={t("ID")} keyName={"IdNo"} {...props}/>
                                        <TableHeadColumn text={t("DOB")} keyName={"BirthDate"} {...props}/>
                                        <TableHeadColumn text={t("Age")} keyName={"age"} {...props}/>
                                        <TableHeadColumn text={t("Cellphone")} keyName={"Cellphone"} {...props}/>
                                        <TableHeadColumn text={t("Home Telephone")} keyName={"Home Telephone"} {...props}/>
                                        <TableHeadColumn text={t("Member Type")} keyName={"Member Type"} {...props}/>
                                        <TableHeadColumn text={t("Profession")} keyName={"Profession"} {...props}/>
                                        <TableHeadColumn text={t("Home Address")} keyName={"Home Address"} {...props}/>
                                        <TableHeadColumn text={t("Last Update")} keyName={"Last Update"} {...props}/>
                                    </tr>
                                    </thead>
                                    <tbody>

                                    {members?.map((member, memberIndex) => {
                                        return (
                                            // <tr key={memberIndex} onContextMenu={(e) => rowRightClick(e, member)}>
                                            <tr key={memberIndex} onClick={(e) => rowClick(e, member)}>
                                                {/*<td>{member?.id}</td>*/}
                                                <td>{member?.UniqueLidNo}</td>
                                                {/*<td>{member?.SeqNo}</td>*/}
                                                <td>{member?.Names}</td>
                                                <td>{member?.FullNames}</td>
                                                <td>{member?.Surname}</td>
                                                <td>{member?.HeadNo === 1 ? t("Head Member") : member?.SpouseNo === 1 ? t("Spouse") : t("Child")}</td>
                                                <td>{member?.IdNo}</td>
                                                <td>{member?.BirthDate}</td>
                                                <td>{member?.age}</td>
                                                <td>{member?.phone_cell}</td>
                                                <td>{member?.phone_tel}</td>
                                                <td>{member?.type}</td>
                                                <td>{member?.profession}</td>
                                                <td>{member?.home_address?.street_address}, {member?.home_address?.suburb}</td>
                                                <td>{member?.date_updated}</td>
                                            </tr>
                                        )
                                    })}

                                    </tbody>
                                </table>

                            </div>
                            <div>
                                <Paginator
                                    page={page}
                                    setPage={setPage}
                                    totalPages={totalPages}
                                    className={"btn btn-sm px-2 py-1"}
                                    containerClassName={"mt-2"}

                                />
                                <div className="d-flex justify-content-center my-2">
                                    <span>{t("Page")} {page} {t("of")} {totalPages}</span>
                                </div>
                            </div>

                        </div>
                    </div>
        </>
    )
}