import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import Modal from 'react-bootstrap/Modal';
import {FaArrowDown, FaArrowUp, FaPaperPlane, FaSave, FaTimes, FaUserPlus, FaSearch} from "react-icons/fa";
import {BsSearch} from "react-icons/bs";
import {getCookie} from "../func/Func";
import toast from "react-hot-toast";
import {ConfirmModal} from "./ConfirmModal";
import {FamilyMemberModal} from "./FamilyMemberModal";
import {IconArrowUp, IconArrowDown} from "@tabler/icons-react";
import Dropdown from 'react-bootstrap/Dropdown';
import {BirthdayMessageModal} from "./BirthdayMessageModal";
import Popover from 'react-bootstrap/Popover';
import {usePermissions} from "../hooks/PermissionContext";

export const AddAttendeesModal = (props) => {
    const {t} = useTranslation();
    const permissions = usePermissions();
    const {
        show,
        onShow,
        onHide,
        eventId,
        user,

    } = props;

    const [members, setMembers] = useState([]);
    const [attendees, setAttendees] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(undefined);
    const [attendeesReminded, setAttendeesReminded] = useState([]);
    const [confirmModalText, setConfirmModalText] = useState("Are you sure you want to add this family?");
    const [confirmModalTitle, setConfirmModalTitle] = useState("Add Family");
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showReminderModal, setShowReminderModal] = useState(false);
    const [newAttendeesSaved, setNewAttendeesSaved] = useState(false);
    const [searchItem, setSearchItem] = useState("");
    const [filteredAttendees, setFilteredAttendees] = useState(attendees);


    const handleInputChange = (e) => {
        const searchItem = e.target.value;
        setSearchItem(searchItem)

        const filteredItems = attendees.filter((attendee) => {
            if (attendee?.Names !== null) {
                return attendee.Names.toLowerCase().includes(searchItem.toLowerCase());
            }
            return false;
        });
        setFilteredAttendees(filteredItems);
    };

    useEffect(() => {
        setSelectedEvent((prevState) => {
            return eventId
        })
        getAttendees()
    }, [eventId]);


    const getAttendees_api = () => {
        return new Promise((resolve, reject) => {
            fetch(`/apiv2/get_new_attendees`, {}).then(res => res.json())
                // .then((data) => data.json())
                .then((data) => {
                    if (data.success) {
                        resolve(data.data)
                    } else {
                        reject(data)
                    }
                })
                .catch(err => {
                    reject(err);
                })
        })
    }
    const getAttendees = () => {

        getAttendees_api()
            .then((data) => {
                setAttendees(data)
                setFilteredAttendees(data)
            })
            .catch((err) => {
                toast.error(err)
            })
    }

    const updateEventAttendees_api = () => {
        return new Promise((resolve, reject) => {
            let url = `/apiv2/update_event_attendees/`;
            fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-CSRFToken": getCookie("csrftoken"),
                },
                body: JSON.stringify({
                    event: selectedEvent,
                }),
            }).then((data) => data.json())
                .then((data) => {
                    // console.log(data)
                    // console.log("updateEventAttendees_api: ", data);
                    if (data.errorCode === 0) {
                        resolve(data);
                        // console.log("resolved")
                    } else {
                        console.log(data.errorMessage);
                        reject(data);
                    }
                })
                .catch((err) => {
                    // console.log(err);
                    reject(err);
                });
        });
    }
    const updateEventAttendees = () => {

        updateEventAttendees_api().then((data) => {
            // console.log('success data:', data)
            toast.custom(t("Attendees updated Successfully"))
        }).catch((err) => {
                // console.log('Error:', err)
                toast.error(t("Attendees updated Unsuccessful"))
            }
        )
        // alert(`Birthday Message Sent: ${message}: ${selectedbirthday.BirthDate}`);
        setNewAttendeesSaved(true)
    }

    const SendMessage_api = (data, eventId) => {
        return new Promise((resolve, reject) => {
            let url = `/apiv1/generate_sms_reminder/`;
            fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-CSRFToken": getCookie("csrftoken"),
                },
                body: JSON.stringify({
                    recipient: [data.id],
                    sms: true,
                    subject: eventId.description,
                     message: t('reminder_message', {
                    description: eventId.description,
                    startdate: eventId.startdate,
                    venue: eventId.venue
                }),
                    // message: "This is a reminder of " + eventId.description + " on " + eventId.startdate + " at " + eventId.venue,
                }),
            }).then((data) => data.json())
                .then((data) => {
                    // console.log(data)
                    // console.log("SendMessage_api: ", data);
                    if (data.errorCode === 0) {
                        resolve(data);
                        // console.log("resolved")
                    } else {
                        // console.log(data.errorMessage);
                        reject(data);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    reject(err);
                });
        });

    }


    const sendReminder = (e, selectedEvent) => {
        if (newAttendeesSaved) {
            let eventparticipants = {...selectedEvent}
            setAttendeesReminded(eventparticipants.participants)
            // console.log("event participants: ", eventparticipants.participants)
            eventparticipants.participants.forEach((attendee) => {
                SendMessage_api({id: attendee.id})
                    .then((data) => {
                        // console.log("data", data)
                        // console.log("reminder successfully sent to: " + attendee.FullNames)
                    })
                    .catch((err) => {
                        // console.log("Error sending reminder to " + attendee.name, err)
                    })

            })
            onHide()
            toast.custom(t('success_reminder_toast', {
                    description: eventId.description,
                    startdate: eventId.startdate,
                    venue: eventId.venue
                }))
            // toast.success("Reminders sent successfully: " + "This is a reminder of " + eventId.description + " on " + eventId.startdate + " at " + eventId.venue)

        } else {
            toast.custom(t("Save New attendees before you proceed"))
        }

    }


    const handleShowReminderModal = () => {
        setShowReminderModal(true);
    }

    const handleCloseReminderModal = () => {
        setShowReminderModal(false);

    }


    const onModalHide = () => {
        if (members?.length > 0) {
            setConfirmModalTitle(t("Lose Changes?"));
            setConfirmModalText(t("Are you sure you want to lose these changes?"));
            setShowConfirmModal(true);
        } else {
            onHide();
        }
    }


    const AddAttendees = (e, attendee) => {
        let newEvent = {...selectedEvent}
        let newAttendees = attendees.filter((part) => part.id !== attendee.id)
        newEvent.participants.push(attendee)
        setAttendees(newAttendees)
        setSelectedEvent(newEvent)

    }

    const RemoveAttendee = (e, participant) => {
        let newEvent = {...selectedEvent}
        let newParticipants = newEvent.participants.filter((part) => part.id !== participant.id)
        newEvent.participants = newParticipants
        let newAttendees = [...attendees, participant]
        setAttendees(newAttendees)
        setSelectedEvent(newEvent)

    }

    return (
        <div>
            <Modal
                size={'md'}
                show={show}
                onHide={onHide}
                centered={true}
                className={"modal-blur "}
                backdrop={"static"}

            >
                <Modal.Body style={{maxHeight: 500, overflowY: "scroll"}}>
                    <div className="row ">
                        <div className="d-flex col-lg-12" style={{justifyContent: "space-between"}}>
                            <h2>
                                {t('current_attendees',{
                                    description: selectedEvent?.description,
                                    participants: selectedEvent?.participants.length
                                })}
                            </h2>
                            <FaTimes size={20} className={"ms-auto cursor-pointer"} onClick={onModalHide}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <ol style={{listStyleType: "none"}}>
                                {selectedEvent?.participants.map((part, index) => {
                                    return (

                                        <li key={index}
                                            style={{backgroundColor: index % 2 === 0 ? 'rgba(56,73,94,0.71)' : ''}}>
                                            <FaArrowDown
                                                style={{marginLeft: "10px", cursor: "pointer", color: "orangered"}}
                                                tooltip={"Remove Attendee"}
                                                onClick={(e) => RemoveAttendee(e, part)}

                                            />
                                            {part.Names} {part.Surname}</li>
                                    )
                                })}
                            </ol>
                            <span style={{display: "flex", justifyContent: "space-between"}}>
                                <button className="btn rounded btn-outline-primary d-flex position: absolute;
                                              right: 0;
                                              top: 50%;
                                              transform: translateY(-50%);
                                            " style={{marginBottom: 20}} onClick={updateEventAttendees}>
                                    <FaSave size={20}/>
                                    <span className={"ms-2"}>{t('Save Attendees')}</span>
                                </button>
                                {permissions?.permissions?.includes('send_messages') &&<button className="btn rounded btn-outline-primary d-flex position: absolute;
                                              right: 0;
                                              top: 50%;
                                              transform: translateY(-50%);
                                            " style={{marginBottom: 20}}
                                         onClick={(e) => sendReminder(e, selectedEvent)}>
                                    <FaPaperPlane size={20}/>
                                    <span className="d-flex">{t("Send Reminder")}</span>
                                </button>}
                                <BirthdayMessageModal
                                    selectedbirthday={attendeesReminded}
                                    show={showReminderModal}
                                    onShow={handleShowReminderModal}
                                    onHide={handleCloseReminderModal}
                                    user={user}/>
                            </span>
                            <div style={{display: "flex", justifyContent: "space-between"}}>
                                <h2>{t("Select Attendees")}</h2>
                                <div>
                                    <BsSearch
                                        style={{
                                            marginRight: "10px",
                                            cursor: "pointer",
                                            color: "limegreen",
                                            fontSize: 20
                                        }}
                                    />
                                    <input type="text"
                                           value={searchItem}
                                           onChange={handleInputChange}
                                           placeholder={t("Type to search...")}
                                    >

                                    </input>
                                </div>
                            </div>
                            {filteredAttendees.length === 0 ? <p>Not Found</p>
                                : <ol style={{listStyleType: "none"}}>
                                    {filteredAttendees?.map((attendee, index) => {
                                        return (
                                            <li key={index}
                                                style={{backgroundColor: index % 2 === 0 ? 'rgba(56,73,94,0.71)' : ''}}>
                                                <FaArrowUp
                                                    style={{marginLeft: "10px", cursor: "pointer", color: "limegreen"}}
                                                    onClick={(e) => {
                                                        AddAttendees(e, attendee);
                                                    }}
                                                    tooltip={t("Add Attendee")}
                                                />
                                                {attendee.Names}
                                                {attendee.Surname}
                                            </li>

                                        );
                                    })}
                                </ol>}

                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
        ;
}