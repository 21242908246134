import i18n from 'i18next';
import React from 'react';
import {Button} from "@progress/kendo-react-buttons"

const LanguageSwitcher = ({ language, setLanguage }) => {
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    console.log('lng: ', lng);
    setLanguage(lng);
  };

  return (
    <div style={{margin: 10, alignSelf:"flex-start"}}>
        <Button style={{margin: 10}} onClick={() => changeLanguage('en')}>English</Button>
        <Button onClick={() => changeLanguage('afr')}>Afrikaans</Button>
    </div>
  );
};

export default LanguageSwitcher;