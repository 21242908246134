import React, {useState, useEffect} from 'react';
import {useTranslation} from "react-i18next";
import "./Styles/Groups.css";
import {getCookie} from "../func/Func";
import {AddParentGroup} from "../modals/AddParentGroup";
import toast from "react-hot-toast";
import {Button} from "@progress/kendo-react-buttons";
import {FaPlus, FaTimes, FaTrash, FaTrashAlt} from "react-icons/fa";
import {AddGroupMembers} from "../modals/AddGroupMembers";
import {usePermissions} from "../hooks/PermissionContext";


export const Groups = (props) => {
        const {t} = useTranslation();
        const permissions = usePermissions();
        const {
            theme,
            user
        } = props;

        const [groups, setGroups] = useState([]);
        const [showAddGroupModal, setShowAddGroupModal] = useState(false);
        const [showSubgroups, setShowSubgroups] = useState(false);
        const [parentGroupID, setParentGroupID] = useState(null);
        const [clickedGroupIndex, setClickedGroupIndex] = useState(null);
        const [newGroup, setNewGroup] = useState(undefined);
        const [viewMembers, setViewMembers] = useState(false);
        const [showGroupMembersModal, setShowGroupMembersModal] = useState(false);
        const [subGroupId, setSubGroupId] = useState(undefined);
        const [AllMembers, setAllMembers] = useState([]);
        const [selectedGroup, setSelectedGroup] = useState(undefined);
        const [selectedGroupMembers, setSelectedGroupMembers] = useState(undefined);
        const [showAddMemberButton, setShowAddMemberButton] = useState(false);
        const handleShowAddGroupModal = (e, groupId) => {
            setParentGroupID(groupId);

            if (e.target.name === "AddSubGroup") {
                let newGroup = {
                    name: e.target.value,
                    company: user.company_id,
                    parent_group_id: groupId,
                    id: undefined
                }
                setNewGroup(newGroup);
            }

            setShowAddGroupModal(true);
        }
        const handleCloseAddGroupModal = () => {
            setShowAddGroupModal(false);
        }


        useEffect(() => {
            getGroups()
            setShowAddGroupModal(false);
            setViewMembers(false);
            setShowSubgroups(false);
        }, []);


        const get_groups_api = (company_id) => {
            return new Promise((resolve, reject) => {
                fetch(`/apiv2/get_groups`, {})
                    .then(res => {
                        if (!res.ok) {
                            throw new Error('Network response was not ok');
                        }
                        return res.json();
                    })
                    // .then((data) => data.json())
                    .then((data) => {
                        if (data.success) {
                            resolve(data.data)
                        } else {
                            reject(data)
                        }
                    })
                    .catch(err => {
                        reject(err);
                    })
            })
        }

        const getGroups = () => {

            get_groups_api()
                .then((data) => {
                    setGroups(data)
                })
                .catch((err) => {
                    alert(err)
                })
        }

        const addGroup = (group) => {
            fetch(`/apiv2/api_add_group/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': getCookie('csrftoken')
                },
                body: JSON.stringify(group)
            }).then(res => res.json())
                .then(data => {
                    if (data?.success) {
                        setShowAddGroupModal(false);
                        getGroups();
                        toast.success(t("Group added successfully"));
                    } else {
                        toast.error(data?.message);
                    }
                })
                .catch(err => {
                    console.log(err);
                })
        }

        const deleteGroup = (e, group) => {
            let groupid = group.id;

            api_delete_group(groupid)
                .then((data) => {
                    toast.success(t("Group deleted successfully"))
                    getGroups()
                })
                .catch((err) => {
                    toast.error(err)
                })

        }

        const api_delete_group = (group_id) => {
            return new Promise((resolve, reject) => {
                fetch(`/apiv2/api_delete_group/${group_id}`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRFToken': getCookie('csrftoken'),
                        'data': {group_id: group_id}
                    }
                }).then(res => res.json())
                    .then(data => {
                        if (data?.success) {
                            resolve(data)
                        } else {
                            reject(data)
                        }
                    })
                    .catch(err => {
                        reject(err);
                    })
            })
        }


        const handleShowSubgroups = (index) => {
            if (clickedGroupIndex === index) {
                setShowSubgroups(!showSubgroups);
            } else {
                setClickedGroupIndex(index);
                setShowSubgroups(true);
            }
        }

        const handleViewMembers = (e, subgroup) => {
            getGroups();
            setSelectedGroup(subgroup);
            setSelectedGroupMembers(subgroup.users);
            setViewMembers(!viewMembers);
            setShowAddMemberButton(!showAddMemberButton);
        }

        const handleCloseGroupMembersModal = () => {
            setViewMembers(false);
            setShowAddMemberButton(false);
            setShowGroupMembersModal(false);
        }

        const handleShowGroupMembersModal = (e, subgroup) => {
            setViewMembers(true);
            setShowGroupMembersModal(true);
            setSelectedGroup(subgroup);
            setSelectedGroupMembers(subgroup.users);
            setSubGroupId(subgroup.id);
        }
        return (
            <div>
                {viewMembers && permissions?.permissions?.includes("modify_groups") &&
                    <AddGroupMembers
                        show={showGroupMembersModal}
                        onShow={handleShowGroupMembersModal}
                        onHide={handleCloseGroupMembersModal}
                        subgroupid={subGroupId}
                        setsubgroupid={setSubGroupId}
                        user={user}
                        setAllMembers={setAllMembers}
                        selectedGroup={selectedGroup}
                        setSelectedGroup={setSelectedGroup}
                        AllMembers={AllMembers}
                        selectedGroupMembers={selectedGroupMembers}


                    />}
                {groups.length > 0 ? (
                        <table className="table-container">
                            <thead>
                            <tr>
                                <th className="table-header"><h1>{t("Groups")}</h1></th>
                            </tr>
                            </thead>
                            <tbody>
                            {groups.filter(group => !group.parent_group_id).map((group, index) => (
                                    // let subgroup === null

                                    <React.Fragment key={index}>
                                        <tr className={`main-group-row ${showSubgroups && clickedGroupIndex !== index ? 'blurred' : ''}`}>
                                            <td className="main-group-cell"
                                                onClick={() => handleShowSubgroups(index)}
                                                title={t("Click to view subgroups")}
                                            ><h2>{group.name}</h2>
                                            </td>
                                            {permissions?.permissions?.includes("modify_groups") &&
                                                <td>
                                                    <FaTrash size={20} className={"ms-auto cursor-pointer"} color="orangeRed"
                                                             onClick={(e) => deleteGroup(e, group)}/>
                                                </td>}
                                        </tr>
                                        {showSubgroups && clickedGroupIndex === index && (
                                            // when this is visible the background must be a bit blurry
                                            <tr className="subgroup-container">
                                                {groups.filter((g) => g.parent_group_id === group.id).length > 0 ? (
                                                    <>
                                                        <td colSpan="2">
                                                            {groups.filter((g) => g.parent_group_id === group.id).map((subgroup, subIndex) => (
                                                                <div key={subIndex} className="subgroup-row">

                                                                    <div className="subgroup-cell"><h2>{subgroup.name}</h2></div>
                                                                    <div className="button-container">
                                                                        <Button
                                                                            onClick={(e) => handleViewMembers(e, subgroup)}>
                                                                            {viewMembers && selectedGroup?.id === subgroup.id ? (t("Hide Members")) : (t("View Members"))}</Button>
                                                                        {showAddMemberButton && selectedGroup?.id === subgroup.id &&

                                                                            <Button
                                                                                disabled={!permissions?.permissions?.includes("modify_groups")}
                                                                                onClick={(e) => handleShowGroupMembersModal(e, subgroup)}>
                                                                                {t("Edit Members")}
                                                                            </Button>
                                                                        }
                                                                        {showAddMemberButton && selectedGroup?.id === subgroup.id &&
                                                                            <FaTrash size={20}
                                                                                     className={"ms-auto cursor-pointer "}
                                                                                     style={{
                                                                                         color: "lightgray",
                                                                                         padding: "2px",

                                                                                     }}

                                                                                     onClick={(e) => deleteGroup(e, subgroup)}/>}
                                                                    </div>

                                                                    {viewMembers && selectedGroup?.id === subgroup.id &&

                                                                        (<div className="view-members-container">

                                                                            {subgroup.users.length > 0 && subgroup.users.map((userId, index) => {
                                                                                const user = AllMembers.find((m) => m.id === userId);
                                                                                return user &&

                                                                                    <div key={index} className="member-row">
                                                                                        <h3> {user.FullNames} {user.Surname}</h3>
                                                                                    </div>


                                                                            })}
                                                                            {subgroup.users.length === 0 &&
                                                                                <div className="member-row">
                                                                                    <h2> {t("No members")}</h2>
                                                                                </div>}
                                                                        </div>)}
                                                                </div>


                                                            ))}
                                                            <div className="button-container">
                                                                <Button name="AddSubGroup"
                                                                        className="add-subgroup-button"
                                                                        size={"small"}
                                                                        onClick={(e) => handleShowAddGroupModal(e, group.id)}>
                                                                    <FaPlus/>{t("Add Subgroup")}
                                                                </Button>
                                                            </div>

                                                        </td>
                                                    </>

                                                ) : (
                                                    <td style={{margin: '2px'}}>
                                                        <h1>No subgroups</h1>
                                                        <Button name="AddSubGroup"

                                                                onClick={(e) => handleShowAddGroupModal(e, group.id)}>{t("Add Subgroup")}</Button>
                                                    </td>
                                                )}
                                            </tr>
                                        )}
                                    </React.Fragment>
                                )
                            )}
                            </tbody>
                        </table>
                    ) :
                    (
                        <p>{t("No groups")}</p>
                    )
                }
                {
                    !showSubgroups &&
                    <div style={{margin: "10px", padding: "10px"}}>
                        <Button
                            name="AddGroup"
                            onClick={(e) => handleShowAddGroupModal(e)}>
                            {t("Add Group")}
                        </Button>
                    </div>
                }
                {
                    showAddGroupModal && <AddParentGroup
                        showAddGroupModal={showAddGroupModal}
                        setShowAddGroupModal={setShowAddGroupModal}
                        onShow={handleShowAddGroupModal}
                        onHide={handleCloseAddGroupModal}
                        user={user}
                        groups={groups}
                        setGroups={setGroups}
                        addGroup={addGroup}
                        parentGroupID={parentGroupID}
                        parentGroup={groups[parentGroupID]}
                    />
                }
            </div>
        )
            ;
    }
;

export default Groups;
