import { createContext, useContext, useMemo } from "react";
import {useLocalStorage} from "../components/useLocalStorage";

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {

  const [theme, setTheme] = useLocalStorage("theme", null);
  if(theme === null){
    setTheme('light');
    document.body.setAttribute('data-bs-theme', 'light');
  }else{
    document.body.setAttribute('data-bs-theme', theme);
  }


  const toggleTheme = () => {
    let newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    document.body.setAttribute('data-bs-theme', newTheme);
  };

  const value = useMemo(
    () => ({
      toggleTheme,
      theme,
    }),
    [theme]
  );

  return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>;
};

export const useTheme = () => {
  return useContext(ThemeContext);
};