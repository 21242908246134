import {useEffect, useState} from "react";


export function useEnv() {
    const [env, setEnv] = useState({});

    useEffect(() => {
        const items = process.env;
        var obj = {};
        for (var key in items) {
            if (key.startsWith("REACT_APP_")) {
                obj[key] = items[key];
            }
        }
        setEnv(obj);
    }, []);

    return {env};
}