import React from 'react';
import {FaSortUp, FaSortDown, FaSortAlphaUp, FaSortAlphaDown} from "react-icons/fa";


export default function TableHeadColumn(props) {
  const {sortOrder, sortBy, setSortBy, setSortOrder, text, keyName} = props;

  const clicked = () => {
    if (sortBy === keyName) {
      if (sortOrder === 'asc') {
        setSortOrder('desc');
      } else {
        setSortOrder('asc');
      }
    } else {
      setSortBy(keyName);
      setSortOrder('asc');
    }
  }

  const SortIcon = (props) => {
    let {size, keyName} = props;
    if (sortBy === keyName) {
      if (sortOrder === 'asc') {
        return <FaSortAlphaDown {...props} className={"text-primary"}/>
      } else {
        return <FaSortAlphaUp {...props} className={"text-primary"}/>
      }
    }
    return <FaSortAlphaUp {...props} />
  }

  const TextElement = () => {
    let {keyName} = props;
    let className = "";
    if (sortBy === keyName) {
      if (sortOrder === 'asc') {
        className = "text-primary";
      } else {
        className = "text-primary";
      }
    }
    return (
      <span className={className}>
        {text}
      </span>
    )
  }

  return (
    <th style={{whiteSpace: 'nowrap'}} className={"cursor-pointer"} onClick={clicked}>
      <TextElement {...props}/>

      {sortBy === keyName ? (
          <>
            <SortIcon size={12} {...props}/>
          </>
        ) :
        (
          <SortIcon size={12} {...props}/>
        )}
    </th>
  )
}