import React, {useState, useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {AddAttendeesModal} from "../modals/AddAttendeesModal";
import {usePermissions} from "../hooks/PermissionContext";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";


const UpComingEvents = (user) => {
        const permissions = usePermissions();
        const {t} = useTranslation();
        const [isLoaded, setIsLoaded] = useState(false);
        const [events, setEvents] = useState([]);
        const [showAttendeesModal, setShowAttendeesModal] = useState(false);
        const [eventId, setEventId] = useState(undefined);

        useEffect(() => {
            getUpcomingEvents()
        }, []);


        const getUpcomingEvents_api = () => {
            return new Promise((resolve, reject) => {
                fetch(`/apiv2/get_upcoming_events/`, {}).then(res => res.json())
                    // .then((data) => data.json())
                    .then((data) => {
                        if (data.success) {
                            resolve(data.data)
                        } else {
                            reject(data)
                        }
                    })
                    .catch(err => {
                        reject(err);
                    })
            })
        }
        const getUpcomingEvents = () => {
            getUpcomingEvents_api()
                .then((data) => {
                    setEvents(data)
                    setIsLoaded(true)
                })
                .catch((err) => {
                    console.log(err)
                })
        }
        const handleCloseAttendeesModal = () => {
            setShowAttendeesModal(false);
        }

        const handleShowAttendeesModal = (e, event) => {
            setShowAttendeesModal(true);
            setEventId(event)
        }

        return (
            <div>
                {isLoaded ? (
                    <span>
                        <h1>{t('Upcoming Events')}</h1>
                        <div style={{overflow: "auto"}}>
                            <table style={{width: '100%', borderCollapse: 'collapse'}}>
                            <thead>
                                <tr>
                                    <th style={{textAlign: 'left', padding: '8px'}}>{t('Event Description')}</th>
                                    <th style={{textAlign: 'left', padding: '8px'}}>{t('Start Date')}</th>
                                    <th style={{textAlign: 'left', padding: '8px'}}>{t('End Date')}</th>
                                    <th style={{textAlign: 'left', padding: '8px'}}>{t('Contact Person')}</th>
                                    <th style={{textAlign: 'left', padding: '8px'}}>{t('Cost')}</th>
                                    <th style={{textAlign: 'left', padding: '8px'}}>{t('Venue')}</th>
                            </tr>
                                </thead>
                                <tbody>
                                {events.map((event, index) => {
                                    return (
                                        <OverlayTrigger
                                            key={index}
                                            placement='top'
                                            overlay={<Tooltip
                                                id={'tooltip-top'}>{permissions?.permissions?.includes('manage_events') && permissions?.permissions?.includes('view_members') ? t('Click to View Attendees') : t('No Permission to View Attendees')}</Tooltip>}
                                        >
                                            <tr key={index}
                                                style={{
                                                    backgroundColor: index % 2 === 0 ? 'rgba(56,73,94,0.71)' : '',
                                                    cursor: permissions?.permissions?.includes('manage_events') && permissions?.permissions?.includes('view_members') ? 'pointer' : 'not-allowed'
                                                }}
                                                onClick={(e) => handleShowAttendeesModal(e, event)}

                                            >
                                                <td style={{padding: '8px'}}

                                                >{event.description}</td>
                                                <td style={{padding: '8px'}}>{new Date(event.startdate).toLocaleString("en-GB", {
                                                    year: "numeric",
                                                    month: "2-digit",
                                                    day: "2-digit",
                                                    hour: "2-digit",
                                                    minute: "2-digit",
                                                    hour12: false,
                                                })}</td>
                                                <td style={{padding: '8px'}}>{new Date(event.startdate).toLocaleString("en-GB", {
                                                    year: "numeric",
                                                    month: "2-digit",
                                                    day: "2-digit",
                                                    hour: "2-digit",
                                                    minute: "2-digit",
                                                    hour12: false,
                                                })}</td>
                                                <td style={{padding: '8px'}}>{event.contactperson}</td>
                                                <td style={{padding: '8px'}}>{event.cost}</td>
                                                <td style={{padding: '8px'}}>{event.venue}</td>
                                            </tr>
                                        </OverlayTrigger>
                                    )
                                })}
                                </tbody>
                    </table>
                        </div>
                        {permissions?.permissions?.includes('manage_events') &&
                            permissions?.permissions?.includes('view_members') &&
                            <AddAttendeesModal
                                show={showAttendeesModal}
                                onShow={handleShowAttendeesModal}
                                onHide={handleCloseAttendeesModal}
                                eventId={eventId}
                                user={user}
                            />}
                    </span>

                ) : (
                    <p>Loading...</p>
                )}
            </div>
        );
    }
;

export default UpComingEvents;
