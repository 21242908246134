import React, {useEffect, useState} from 'react';
import {useTranslation}  from "react-i18next";
import Modal from 'react-bootstrap/Modal';
import {FaSave, FaTimes, FaUserPlus, FaUsers} from "react-icons/fa";
import {getCookie} from "../func/Func";
import toast from "react-hot-toast";
import FamilyTab from "../components/FamilyTab";
import {usePermissions} from "../hooks/PermissionContext";

export const EditFamilyModal = (props) => {
    const {t} = useTranslation();
    const permissions = usePermissions();
    const {
        show,
        onShow,
        onHide,
        selectedFamily,
        setSelectedFamily,
        selectedMember,
        theme,
        getFamily,
        selectedMemberChanged,
        handleShowMemberModal
    } = props;


    return (
        <>
            <Modal
                size={'xl'}
                show={show}
                onHide={onHide}
                // centered={true}
                // className={`modal-blur`}
                className={`modal-custom-backdrop-${theme}`}
                backdrop={"static"}
                // keyboard={members?.length === 0}
                // dialogClassName={`box-shadow-${theme}`}

            >
                <Modal.Body className={`box-shadow-${theme} rounded-3`}>
                    <div className="row">
                        <div className="d-flex col-lg-12">

                            <h3>
                                {t("Family View")}
                                <span className={"badge bg-primary-lt ms-2"}>{selectedFamily?.members?.length} {t("Member(s)")}</span>
                            </h3>
                            <FaTimes size={20} className={"ms-auto cursor-pointer"} onClick={onHide}/>
                        </div>
                    </div>
                    <div className="pt-0">
                        <FamilyTab {...props}
                            setSelectedFamily={props.setSelectedFamily}


                        />
                    </div>

                </Modal.Body>
            </Modal>
        </>
    );
}