import React, {useEffect, useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import {FaSave, FaTimes, FaUserPlus} from "react-icons/fa";
import {getCookie} from "../func/Func";
import toast from "react-hot-toast";
import {ConfirmModal} from "./ConfirmModal";
import {FamilyMemberModal} from "./FamilyMemberModal";
import {useTranslation} from "react-i18next";

export const AddFamilyModal = (props) => {
    const {t} = useTranslation();
    const {
        show,
        onShow,
        onHide,
        user,
        addMembersCallback,
    } = props;

    const [members, setMembers] = useState([]);
    const [confirmModalText, setConfirmModalText] = useState(t("Are you sure you want to add this family?"));
    const [confirmModalTitle, setConfirmModalTitle] = useState(t("Add Family"));
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const [showMemberModal, setShowMemberModal] = useState(false);
    const [selectedMember, setSelectedMember] = useState(null);

    const [newMember, setNewMember] = useState([]);
    const [familyName, setFamilyName] = useState('');
const [streetAddress, setStreetAddress] = useState('');


    const onModalHide = () => {
        if (members?.length > 0) {
            setConfirmModalTitle(t("Lose Changes?"));
            setConfirmModalText(t("Are you sure you want to lose these changes?"));
            setShowConfirmModal(true);
        } else {
            onHide();
        }
    }

    const onHideConfirmModal = () => {
        setShowConfirmModal(false);
    }

    const onConfirm = () => {
        setMembers([]);
        onHide();
        onHideConfirmModal();
    }

    const onCancel = () => {
        onHideConfirmModal();
    }

    const onMemberModalHide = () => {
        setShowMemberModal(false);
    }

    const onAddMember = () => {
        let random_id = Math.floor(Math.random() * 1000000);
        let new_member = {
            id: random_id,
            first_name: "",
            last_name: "",
            dob: "",
            type: "Child",
            ftype:"Child",
            familyid: null,
            family_name: "",
            street_address: ""
        }

        setSelectedMember(new_member);
        setShowMemberModal(true);
        setMembers([...members, new_member]);
    }

    const AddMembers_api = () => {
        return new Promise((resolve, reject) => {
            let url = `/apiv2/add_members/`;
            fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-CSRFToken": getCookie("csrftoken"),
                },
                body: JSON.stringify({
                    members: members


                }),
            }).then((data) => data.json())
                .then((data) => {
                    // console.log(data)
                    // console.log("updateEventAttendees_api: ", data);
                    if (data.errorCode === 0) {
                        resolve(data);
                        console.log("resolved")
                        onHide()
                         addMembersCallback(data.members)
                    } else {
                        console.log(data.errorMessage);
                        reject(data);
                    }
                })
                        .catch((err) => {
                            // console.log(err);
                            reject(err);
                        });
                });
        }
        const addMembers = () => {
            AddMembers_api(members)
                .then((data) => {
                    console.log("Member added successfully", data);
                    toast.success(t("family added successfully"))
                    setMembers([]);
                    setFamilyName('');
                    setStreetAddress('');
                    onHide();
                })
                .catch((err) => {
                    console.error("Error adding member", err);
                });




        }
    //     const valueChanged = (e) => {
    //     if (e.target.name === 'family_name') {
    //     setFamilyName(e.target.value);
    //
    // } else if (e.target.name === 'street_address') {
    //     setStreetAddress(e.target.value);
    // }
    //     setMembers({
    //         ...members,
    //         [e.target.name]: e.target.value
    //     });
    // }
    //

    const valueChanged = (e) => {
    const { name, value } = e.target;
    setMembers((prevMembers) => {
        return prevMembers.map((member) => {
            if (member.id === selectedMember.id) {
                return { ...member, [name]: value };
            }
            return member;
        });
    });

    if (name === 'family_name') {
        setFamilyName(value);
    } else if (name === 'street_address') {
        setStreetAddress(value);
    }
};
        return (
            <>
                <FamilyMemberModal
                    show={showMemberModal}
                    onHide={onMemberModalHide}
                    members={members}
                    setMembers={setMembers}
                    selectedMember={selectedMember}
                    setSelectedMember={setSelectedMember}
                />
                <ConfirmModal
                    show={showConfirmModal}
                    onHide={onHideConfirmModal}
                    onConfirm={onConfirm}
                    onCancel={onCancel}
                    title={confirmModalTitle}
                    message={confirmModalText}
                />
                <Modal
                    size={'xl'}
                    show={show}
                    onHide={onHide}
                    centered={true}
                    className={"modal-blur"}
                    backdrop={"static"}
                    keyboard={members?.length === 0}

                >
                    <Modal.Body>
                        <div className="row">
                            <div className="d-flex col-lg-12">
                                <h3>
                                    {t('Add Family')}
                                    <span className={"badge bg-primary-lt ms-2"}>{members?.length} {t("Member(s)")}</span>
                                </h3>
                                <FaTimes size={20} className={"ms-auto cursor-pointer"} onClick={onModalHide}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <button onClick={addMembers} className="btn rounded btn-outline-primary">
                                    <FaSave size={20}/>
                                    <span className={"ms-2"}>{t("Save")}</span>
                                </button>
                                <button onClick={onAddMember} className={"ms-2 btn btn-outline-primary"}>
                                    <FaUserPlus size={20}/>
                                    <span className={"ms-2"}>{t("Add Member")}</span>
                                </button>
                            </div>
                        </div>
                        <div className="row">
                            {members?.length > 0 && <div className="col-sm-8">
                                <label>{t("Family Name")}</label>
                                <input type="text" className="form-control" name="family_name" value={familyName}
                                       onChange={valueChanged}/>
                                <label>{t("Family Address")}</label>
                                <input type="text" className="form-control" name="street_address" value={streetAddress}
                                       onChange={valueChanged}/>
                            </div>}

                        </div>
                        <div className="row mt-2">

                            {members?.map((member, memberIndex) => {
                                return (
                                    <>
                                        <div className="col-lg-4 mt-3">
                                            <div className="card bg-primary-lt">
                                                <div className="card-body">
                                                    <h4>
                                                        {member?.first_name}
                                                        <span className={"ms-1 badge bg-warning-lt"}>{member?.ftype}</span>
                                                    </h4>
                                                   <table className="table table-borderless table-sm">
                                                        <tbody>
                                                        <tr>
                                                            <td>{t("First Name")}</td>
                                                            <td>{member?.first_name}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>{t("Last Name")}</td>
                                                            <td>{member?.last_name}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>{t("DOB")}</td>
                                                            <td>{member?.dob}</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            })}


                        </div>
                    </Modal.Body>
                </Modal>
            </>
        );
    }