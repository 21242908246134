import React, {useEffect, useState} from "react";
import {FaMousePointer, FaSpinner} from "react-icons/fa";
import {getCookie} from "../func/Func";

export const useIncome = () => {
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [independentFunds, setIndependentFunds] = useState([]);
  const [selectedIndependentFund, setSelectedIndependentFund] = useState(null);
  const [accountsLoading, setAccountsLoading] = useState(false);
  const [independentFundsLoading, setIndependentFundsLoading] = useState(false);
  const [incomeTypes, setIncomeTypes] = useState([]);
  const [incomeTypesLoading, setIncomeTypesLoading] = useState(false);
  const [selectedIncomeType, setSelectedIncomeType] = useState(null);
  const [incomeTypeToEdit, setIncomeTypeToEdit] = useState(null);
  const [incomeTypeRemainingFunds, setIncomeTypeRemainingFunds] = useState(null);
  const [independentFundToEdit, setIndependentFundToEdit] = useState(null);

  useEffect(() => {
    setIncomeTypeToEdit(selectedIncomeType);
    let remainingFunds = independentFunds?.filter((fund) => {
      let found = false;
      selectedIncomeType?.independent_funds?.map((incomeFund) => {
        if (fund.id === incomeFund.id) {
          found = true;
        }
      })
      return !found;
    })
    setIncomeTypeRemainingFunds(remainingFunds);
  }, [selectedIncomeType])

  useEffect(() => {
    if(incomeTypeToEdit) {
      let remainingFunds = independentFunds?.filter((fund) => {
        let found = false;
        incomeTypeToEdit?.independent_funds?.map((incomeFund) => {
          if (fund.id === incomeFund.id) {
            found = true;
          }
        })
        return !found;
      })
      setIncomeTypeRemainingFunds(remainingFunds);
    }
  }, [independentFunds])

  const getAccounts = () => {
    setAccountsLoading(true);
    fetch(`/apiv2/bank_accounts/`, {
      method: 'GET',
    }).then(res => res.json())
      .then(data => {
        if (data?.success) {
          setAccounts(data?.data);
        }
        setAccountsLoading(false);
      })
      .catch(err => {
        console.log(err);
        setAccountsLoading(false);
      })
  }

  const getIncomeTypes = (account) => {
    setIncomeTypesLoading(true);
    fetch(`/apiv2/income_types/${account?.id}/`, {
      method: 'GET',
    }).then(res => res.json())
      .then(data => {
        if (data?.success) {
          setIncomeTypes(data?.data);
        }
        setIncomeTypesLoading(false);
      })
      .catch(err => {
        console.log(err);
        setIncomeTypesLoading(false);
      })
  }

  const getIndependentFunds = (account) => {
    setIndependentFundsLoading(true);
    fetch(`/apiv2/independent_funds/${account?.id}/`, {
      method: 'GET',
    }).then(res => res.json())
      .then(data => {
        if (data?.success) {
          setIndependentFunds(data?.data);
        }
        setIndependentFundsLoading(false);
      })
      .catch(err => {
        console.log(err);
        setIndependentFundsLoading(false);
      })
  }

  const addIndependentFund = (fundName) => {
    return new Promise((resolve, reject) => {
      fetch(`/apiv2/independent_funds/${selectedAccount?.id}/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': getCookie('csrftoken'),
      },
      body: JSON.stringify({
        name: fundName,
      }),
    }).then(res => res.json())
      .then(data => {
        // if (data?.success) {
        //   getIndependentFunds(selectedAccount);
        // }
        resolve(data);
      })
      .catch(err => {
        // console.log(err);
        reject(err);
      })
    })
  }

  const updateIndependentFund = (fund) => {
    return new Promise((resolve, reject) => {
      fetch(`/apiv2/independent_funds/${selectedAccount?.id}/`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': getCookie('csrftoken'),
      },
      body: JSON.stringify(fund),
    }).then(res => res.json())
      .then(data => {
        resolve(data);
      })
      .catch(err => {
        reject(err);
      })
    })
  }

  const deleteIndependentFund = (fund) => {
    return new Promise((resolve, reject) => {
      fetch(`/apiv2/independent_funds/${selectedAccount?.id}/`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': getCookie('csrftoken'),
      },
      body: JSON.stringify(fund),
    }).then(res => res.json())
      .then(data => {
        resolve(data);
      })
      .catch(err => {
        reject(err);
      })
    })
  }

  const LoadingIndicator = (props) => {
    let {height, text, state} = props;
    if(!height) {
      height = 60;
    }
    if(!text) {
      text = "Loading...";
    }
    return (
      <>
        {state && (
          <div className="col-lg-12 d-flex flex-column justify-content-center align-items-center"
               style={{height: `${height}vh`}}>
            <FaSpinner size={100} className={"text-primary"}/>
            <h1 className={"m-0 pt-4 text-muted"}>{text}<span className="animated-dots"></span></h1>
          </div>
        )}
      </>
    )
  }

  return {
    accounts,
    selectedAccount,
    independentFunds,
    getAccounts,
    getIndependentFunds,
    setSelectedAccount,
    accountsLoading,
    independentFundsLoading,
    LoadingIndicator,
    getIncomeTypes,
    incomeTypes,
    incomeTypesLoading,
    addIndependentFund,
    selectedIndependentFund,
    setSelectedIndependentFund,
    updateIndependentFund,
    deleteIndependentFund,
    selectedIncomeType,
    setSelectedIncomeType,
    incomeTypeToEdit,
    setIncomeTypeToEdit,
    incomeTypeRemainingFunds,
    setIncomeTypeRemainingFunds,
    independentFundToEdit,
    setIndependentFundToEdit,
  }
};
