// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content-light {
    background: rgba(240, 248, 255, 0.65);
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
.modal-content-dark {
    background: rgba(24, 36, 51, 0.77);
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
    .modal-content {
        width: 100%;
        padding: 10px;
    }

    .modal-content .row {
        flex-direction: column;
    }

    .modal-content .d-flex {
        flex-direction: column;
    }
}`, "",{"version":3,"sources":["webpack://./src/modals/Styles/changepasswordmodal.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,8BAA8B;IAC9B,kCAA0B;YAA1B,0BAA0B;IAC1B,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,qCAAqC;IACrC,aAAa;IACb,kBAAkB;IAClB,UAAU;IACV,gBAAgB;IAChB,yCAAyC;AAC7C;AACA;IACI,kCAAkC;IAClC,aAAa;IACb,kBAAkB;IAClB,UAAU;IACV,gBAAgB;IAChB,yCAAyC;AAC7C;;AAEA;IACI;QACI,WAAW;QACX,aAAa;IACjB;;IAEA;QACI,sBAAsB;IAC1B;;IAEA;QACI,sBAAsB;IAC1B;AACJ","sourcesContent":[".modal-overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background: rgba(0, 0, 0, 0.5);\n    backdrop-filter: blur(5px);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 1000;\n}\n\n.modal-content-light {\n    background: rgba(240, 248, 255, 0.65);\n    padding: 20px;\n    border-radius: 8px;\n    width: 90%;\n    max-width: 500px;\n    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n}\n.modal-content-dark {\n    background: rgba(24, 36, 51, 0.77);\n    padding: 20px;\n    border-radius: 8px;\n    width: 90%;\n    max-width: 500px;\n    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n}\n\n@media (max-width: 768px) {\n    .modal-content {\n        width: 100%;\n        padding: 10px;\n    }\n\n    .modal-content .row {\n        flex-direction: column;\n    }\n\n    .modal-content .d-flex {\n        flex-direction: column;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
