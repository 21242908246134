import React, { useEffect, useState } from "react";
import Select from "react-select";
import { selectStyles } from "../styles/selectStyles";

const getDescription = (dictDescription, option) => {
  // description can be specified as 'city.name' or 'name' or 'city.name.street'
  let description = "";
  let keys = dictDescription.split(".");
  let temp = option;
  keys.forEach((key) => {
    if (temp) {
      temp = temp[key];
    }
  });
  description = temp;
  return description;
};

const getKey = (dictKey, option) => {
  let key = "";
  let keys = dictKey.split(".");
  let temp = option;
  keys.forEach((key) => {
    if (temp) {
      temp = temp[key];
    }
  });
  key = temp;
  return key;
};

export default function SelectInput(props) {
  let {
    options,
    dictKey,
    dictDescription,
    onChangeCallback,
    onInputCallback,
    valueChanged,
    disabled,
    className,
    initialValue,
    search,
    forcedValue,
    placeholder,
  } = props;

  if(!placeholder) {
    placeholder = "Select...";
  }

  const getSelect2InitialValue = (initialValue, dictKey) => {
    if (!initialValue) return null;
    let key = getKey(dictKey, initialValue);
    let description = getDescription(dictDescription, initialValue);
    return {
      value: key,
      label: description,
    };
  };

  const [selected, setSelected] = useState(initialValue ? initialValue : null);
  const [options2, setOptions2] = useState([]);
  const [selected2, setSelected2] = useState(getSelect2InitialValue(initialValue, dictKey));

  useEffect(() => {
    if (valueChanged) {
      valueChanged(selected);
    }
  }, [selected]);

  useEffect(() => {
    if (forcedValue === null) {
      setSelected(null);
      setSelected2(null);
    }
  }, [forcedValue]);

  useEffect(() => {
    if (options && options.length > 0) {
      setOptions2(
        options.map((option) => {
          let description = getDescription(dictDescription, option);
          let key = getKey(dictKey, option);
          return {
            value: key,
            label: description,
          };
        }),
      );
    }
  }, [options]);

  const selectChanged = (e) => {
    if (onChangeCallback) {
      try {
        onChangeCallback(e);
      } catch (error) {
        console.error("onChangeCallback not defined");
      }
    }

    let val = null;
    try {
      val = JSON.parse(e.target.value);
    } catch (error) {
      val = e.target.value;
    }
    setSelected(val);
    console.log(val);
  };

  const onSelectInput = (e) => {
    if (onInputCallback) {
      try {
        onInputCallback(e);
      } catch (error) {
        console.error("onInputCallback not defined");
      }
    }
  };

  const select2Changed = (select2Value) => {
    let key = select2Value.value;
    options?.map((option) => {
      let tmp_value = getKey(dictKey, option);
      if (tmp_value === key) {
        setSelected(option);
        setSelected2(select2Value);
      }
    });
  };

  if (search) {
    return (
      <Select
        options={options2}
        onChange={select2Changed}
        value={selected2}
        isDisabled={disabled}
        className={className}
        isSearchable={true}
        styles={selectStyles}
        placeholder={placeholder}
      />
    );
  } else {
    return (
      <select
        className={`form-control ${className}`}
        onChange={selectChanged}
        onInput={onSelectInput}
        disabled={disabled}

      >
        {!selected && (
          <option selected={true} disabled={true}>
            {placeholder}
          </option>
        )}
        {options?.map((option) => {
          let isSelected = false;
          if (selected) {
            isSelected = selected[dictKey] === option[dictKey];
          }
          let description = getDescription(dictDescription, option);
          let key = getKey(dictKey, option);
          return (
            <option selected={isSelected} key={key} value={JSON.stringify(option)}>
              {description}
            </option>
          );
        })}
      </select>
    );
  }
}