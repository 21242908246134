export const selectStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: "var(--tblr-bg-forms)",
    border: "var(--tblr-border-width) solid var(--tblr-border-color)",
    borderRadius: "var(--tblr-border-radius)",

    "&:hover": {
      border: "var(--tblr-border-width) solid var(--tblr-border-color)",
    },
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: "var(--tblr-bg-surface)",
    border: "var(--tblr-border-width) solid var(--tblr-border-color)",
  }),
  input: (provided) => ({
    ...provided,
    color: "inherit",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "inherit",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: "var(--tblr-muted)",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: "transparent",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "var(--tblr-primary)" : "var(--tblr-bg-surface)",
    color: state.isSelected ? "white" : "inherit",
    "&:hover": {
      backgroundColor: "var(--tblr-border-color)",
      color: "inherit",
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "inherit",
  }),
  loadingIndicator: (provided) => ({
    ...provided,
    color: "inherit",
  }),
};