import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";

export const NotFound = (props) => {
    const {t} = useTranslation();
    return (
        <>
            <div className="container-fluid">
                <h1>{t("Page not found")}</h1>
            </div>
        </>
    )
}