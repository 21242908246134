import React, {useEffect, useState} from 'react';
import {Navigate, useOutlet} from "react-router-dom";
import {TopNav} from "./TopNav";
import { Toaster } from 'react-hot-toast';
import {useEnv} from "../hooks/useEnv";
import {useAuth} from "../hooks/useAuth";

export const Base = (props) => {
    const outlet = useOutlet();
    // const {env} = useEnv();
    const {user, auth, fetchPermissions} = useAuth();

    useEffect(() => {
        auth().then((logged_in) => {
            if(logged_in === false) {
               return <Navigate to={"/login"}/>
            }
        });
    }, []);

    if(!user) {
        return <Navigate to={"/login"}/>
    }

    return (
        <>
            <Toaster/>
            <div className="page">
                <TopNav {...props}/>
                {outlet}
            </div>
        </>
    )
}