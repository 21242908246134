import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import Modal from 'react-bootstrap/Modal';
import {FaSave, FaTimes, FaTrash, FaEye, FaEyeSlash} from "react-icons/fa";
import {getCookie} from "../func/Func";
import toast from "react-hot-toast";
import {ConfirmModal} from "./ConfirmModal";

export const RealModalBody = (props) => {
  const {t} = useTranslation();
  const {
    show,
    onShow,
    onHide,
    theme,
    banks,
    selectedAccount,
    saveAccount,
    deleteAccount,
  } = props;
  const [accountToEdit, setAccountToEdit] = useState(null);
  const [showAccountNumber, setShowAccountPassword] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  useEffect(() => {
    if (selectedAccount) {
      setAccountToEdit(selectedAccount);
    } else {
      setAccountToEdit(null);
    }
  }, [selectedAccount])

  const accountHolderChanged = (e) => {
    setAccountToEdit({...accountToEdit, account_holder: e.target.value});
  }

  const accountNameChanged = (e) => {
    setAccountToEdit({...accountToEdit, name: e.target.value});
  }

  const accountNumberChanged = (e) => {
    if(showAccountNumber){
      setAccountToEdit({...accountToEdit, decrypted_account_number: e.target.value});
    }else{
      setAccountToEdit({...accountToEdit, account_number: e.target.value});
    }
  }

  const bankChanged = (e) => {
    let bank_json = JSON.parse(e.target.value);
    setAccountToEdit({...accountToEdit, bank: bank_json});
  }

  const showAccountNumberChanged = (e) => {
    setShowAccountPassword(!showAccountNumber);
  }

  const deleteAccountClicked = () => {
    setShowConfirmModal(true);
  }

  const saveAccountClicked = () => {
    saveAccount(accountToEdit);
  }

  return (
    <>
      <ConfirmModal
        show={showConfirmModal}
        onHide={() => setShowConfirmModal(false)}
        onConfirm={() => {
          setShowConfirmModal(false);
          deleteAccount(selectedAccount);
          onHide();
        }}
        onCancel={() => setShowConfirmModal(false)}
        title={t("Delete Account")}
        message={t("Are you sure you want to delete this account?")}
      />
      <Modal.Body className={`box-shadow-${theme} rounded-3`}>
        <div className="row">
          <div className="d-flex col-lg-12">

            <h3 className={"d-flex"}>
              {t("Editing")}
              <span className={"ms-1 badge bg-primary-lt"}>{accountToEdit?.name}</span>
              <span className={"ms-1 badge bg-danger-lt d-flex align-items-center justify-items-center cursor-pointer"} onClick={deleteAccountClicked}>
                <FaTrash size={10} className={"me-1 cursor-pointer"}/>
                {t("Delete")}
              </span>
            </h3>
            <FaTimes size={20} className={"ms-auto cursor-pointer"} onClick={onHide}/>
          </div>
        </div>
        <div className="pt-0">
          <form action="#" className={"row"}>
            <div className="col-lg-6 mb-2">
              <div className="form-group">
                <label htmlFor="name">{t("Account Name")}</label>
                <input onInput={accountNameChanged} value={accountToEdit?.name} required={true} type="text"
                       className="form-control" id="name"
                       placeholder={t("Account Name")}/>
              </div>
            </div>

            <div className="col-lg-6 mb-2">
              <div className="form-group">
                <label htmlFor="account_holder">{t("Account Holder")}</label>
                <input onInput={accountHolderChanged} value={accountToEdit?.account_holder} required={true} type="text"
                       className="form-control" id="account_holder"
                       placeholder={t("Account Holder")}/>
              </div>
            </div>

            <div className="col-lg-6 mb-2">
              <div className="form-group">
                <label htmlFor="account_number">
                  {t("Account Number")}
                </label>

                <div className="row g-2">
                  <div className="col">
                    <input
                      pattern="\d+"
                      disabled={!showAccountNumber}
                      onInput={accountNumberChanged}
                      value={showAccountNumber ? accountToEdit?.decrypted_account_number : accountToEdit?.account_number}
                      type={showAccountNumber ? "text" : "password"} className="form-control"
                      placeholder={t("Account Number")}/>
                  </div>
                  <div className="col-auto">
                    <a href="#" className="btn btn-icon" aria-label="Button" onClick={showAccountNumberChanged}>
                      {showAccountNumber ? (
                        <FaEyeSlash className={"cursor-pointer"}/>
                      ) : (
                        <FaEye className={"cursor-pointer"}/>
                      )}
                    </a>
                  </div>
                </div>

                {/*<div className="input-icon mb-0">*/}
                {/*  <input*/}
                {/*    type={showAccountNumber?"text":"password"}*/}
                {/*    className="form-control"*/}
                {/*    value={showAccountNumber?accountToEdit?.decrypted_account_number:accountToEdit?.account_number}*/}
                {/*    placeholder="Search…"*/}
                {/*    id={"account_number"}*/}
                {/*  />*/}
                {/*  <span className="input-icon-addon">*/}
                {/*    {showAccountNumber ? (*/}
                {/*      <FaEyeSlash className={"cursor-pointer"}/>*/}
                {/*    ) : (*/}
                {/*      <FaEye className={"cursor-pointer"}/>*/}
                {/*    )}*/}
                {/*  </span>*/}
                {/*</div>*/}


              </div>
            </div>

            <div className="col-lg-6 mb-2">
              <div className="form-group">
                <label htmlFor="bank">{t("Bank")}</label>
                <select onInput={bankChanged} name="" id="bank" className="form-control">
                  {!accountToEdit?.bank && (
                    <option selected={true} disabled={true}>{t("Select Bank")}</option>
                  )}
                  {banks?.map((bank, index) => {
                    let selected = accountToEdit?.bank?.id === bank.id;
                    return (
                      <option selected={selected} key={index} value={JSON.stringify(bank)}>{bank.name}</option>
                    )
                  })}
                </select>
              </div>
            </div>

            <div className="col-lg-6 mb-2">
              <div className="form-group">
                <label htmlFor="name">{t("Branch Code")}</label>
                <input type="text" value={accountToEdit?.bank?.branch_code} readOnly={true}
                       className="form-control cursor-not-allowed" id="name" placeholder={t("Branch Code")}/>
              </div>
            </div>

            <div className="col-lg-12">
              <button onClick={saveAccountClicked} type={'button'} className={`btn btn-primary w-100`}>
                <FaSave className={"me-2"}/>
                {t("Save")}
              </button>
            </div>
          </form>
        </div>

      </Modal.Body>
      </>
      );
      }

export const EditAccountModal = (props) => {

  let {show, onShow, onHide, theme} = props;
  return <>
    <Modal
      size={'md'}
      show={show}
      onHide={onHide}
      // centered={true}
      // className={`modal-blur`}
      className={`modal-custom-backdrop-${theme}`}
      backdrop={"static"}
      // dialogClassName={`box-shadow-${theme}`}

    >
      {show && (
        <RealModalBody {...props}/>
      )}
    </Modal>
  </>
}