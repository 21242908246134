import React, {useEffect, useState} from 'react';
import toast from "react-hot-toast";
import {useTranslation} from "react-i18next";
import {AutoComplete, ComboBox} from "@progress/kendo-react-dropdowns";
import {Checkbox, Input, Switch} from "@progress/kendo-react-inputs";
import {Button} from "@progress/kendo-react-buttons";
import {getCookie} from "../func/Func";
import data from "bootstrap/js/src/dom/data";
import {AddNewUserModal} from "../modals/AddNewUserModal";
import {FaPlus, FaPlusCircle, FaTrash} from "react-icons/fa";
import "./styles.css";
import {Form, Field, FormElement, FieldWrapper} from '@progress/kendo-react-form';
import {usePermissions} from "../hooks/PermissionContext";

const PermissionProfiles = (props) => {
    const {t} = useTranslation();
    const perm = usePermissions();
    const {
        user,
        theme,
        getPermissionProfiles,
        permissions,
        allMembers,
    } = props;

    const [selectedProfile, setSelectedProfile] = useState(null);
    const [profileSelected, setProfileSelected] = useState(false);
    const [showAddNewUserModal, setShowAddNewUserModal] = useState(false);
    const [showAddProfileModal, setShowAddProfileModal] = useState(false);
    const [newProfile, setNewProfile] = useState(null);
    const [showAddProfile, setShowAddProfile] = useState(false);

    useEffect(() => {
        setSelectedProfile(null);
        setProfileSelected(false);
        setShowAddNewUserModal(false);
        getPermissionProfiles(getPermissionProfiles_api);
    }, []); // Empty dependency array ensures this runs only once


    const getPermissionProfiles_api = () => {
        return new Promise((resolve, reject) => {
            fetch(`/apiv2/api_permissions`, {})

                .then(res => {
                    if (!res.ok) {
                        return res.text()
                            .then(text => {
                                throw new Error(t("Failed to load permission profiles: "));
                            });
                    }
                    return res.json();
                })

                // .then((data) => data.json())
                .then((data) => {
                    if (data.success) {
                        resolve(data)


                    } else {
                        reject(new Error(t("Failed to load permission profiles: ")));
                    }
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    const handleSubmit = (dataItem) => {
        toast.custom(JSON.stringify(dataItem, null, 2))
        setNewProfile(dataItem)
        addNewProfile_api(dataItem)
        setShowAddProfile(false)

    };

    const addNewProfile_api = (dataItem) => {
        return new Promise((resolve, reject) => {
            fetch(`/apiv2/add_permission_profile/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "X-CSRFToken": getCookie("csrftoken"),

                },
                body: JSON.stringify({
                    profileName: dataItem.profileName,
                    // profileId: permissions.find(profile => profile.name === selectedProfile).id,
                    // permissionId: permissionId,
                    // isChecked: isChecked,
                }),
            })
                .then((data) => data.json())
                .then((data) => {
                    if (data.success) {
                        resolve(data)
                        getPermissionProfiles(getPermissionProfiles_api)

                    } else {
                        reject(new Error(t("Failed to update permission profile permissions")));
                    }
                })
        })
            .catch(error => {
                toast.error(t('Failed to update permission: ') + error.message);
            })
    }


    const selectedProfilePermissions = permissions.find(
        profile => profile.name === selectedProfile
    )?.permissions || [];

    const handleChangePermissionProfile = (event) => {
        setSelectedProfile(event.target.value);
        setProfileSelected(true);
    }

    const handleDefaultChecked = (permission) => {
        return selectedProfilePermissions.some(
            selectedPermission => selectedPermission.id === permission.id
        )
    }

    const handleCheckboxChange = (event, permissionId) => {
        const isChecked = event.target.value;

        // Make an API call to update the database
        return new Promise((resolve, reject) => {
            fetch(`/apiv2/update_permission_profile_permissions/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "X-CSRFToken": getCookie("csrftoken"),

                },
                body: JSON.stringify({
                    profileName: selectedProfile,
                    profileId: permissions.find(profile => profile.name === selectedProfile).id,
                    permissionId: permissionId,
                    isChecked: isChecked,
                }),
            })
                .then((data) => data.json())
                .then((data) => {
                    if (data.success) {
                        resolve(data)
                        getPermissionProfiles(getPermissionProfiles_api)

                    } else {
                        reject(new Error(t("Failed to update permission profile permissions")));
                    }
                })
        })
            .catch(error => {
                toast.error(t('Failed to update permission: ') + error.message);
            })
    }

    const handleUserActiveChange = (event, userId) => {
        console.log("value", event.target.value)
        console.log("userId", userId)
        const isActive = event.target.value;
        activate_user_api(userId, isActive)
            .then((data) => {
                toast.success(t("User permissions updated successfully"))
                // getPermissionProfiles(getPermissionProfiles_api)

            })
            .catch((err) => {
                toast.error(t("An error occurred while updating user permissions") + err.message)
            })

    }

    const activate_user_api = (userId, isActive) => {
        return new Promise((resolve, reject) => {
            fetch(`/apiv2/activate_user_api/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "X-CSRFToken": getCookie("csrftoken"),

                },
                body: JSON.stringify({
                    profileName: selectedProfile,
                    userId: userId,
                    isActive: isActive,
                }),
            })
                .then((data) => data.json())
                .then((data) => {
                    if (data.success) {
                        resolve(data)
                        getPermissionProfiles(getPermissionProfiles_api)

                    } else {
                        reject(new Error(t("Failed to update permission profile permissions")));
                    }
                })
        })
            .catch(error => {
                toast.error(t('Failed to update permission: ') + error.message);
            })
    }

    const handleDeleteUser = (event, userId) => {
        delete_user_api(userId)
            .then((data) => {
                toast.success(t("User deleted successfully"))
                getPermissionProfiles(getPermissionProfiles_api)

            })
            .catch((err) => {
                toast.error(t("An error occurred while deleting user") + err.message)
            })
    }

    const delete_user_api = (userId) => {
        return new Promise((resolve, reject) => {
            fetch(`/apiv2/add_user_to_profile/`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    "X-CSRFToken": getCookie("csrftoken"),

                },
                body: JSON.stringify({
                    profileName: selectedProfile,
                    userId: userId,
                }),
            })
                .then((data) => data.json())
                .then((data) => {
                    if (data.success) {
                        resolve(data)
                        getPermissionProfiles(getPermissionProfiles_api)

                    } else {
                        reject(new Error(t("Failed to update permission profile permissions")));
                    }
                })
        })
            .catch(error => {
                toast.error(t('Failed to update permission: ') + error.message);
            })
    }

    const handleDeleteProfile = (event, profileId) => {
        console.log("profileId", profileId)
        delete_profile_api(profileId)
            .then((data) => {
                toast.success(t("Profile deleted successfully"))
                getPermissionProfiles(getPermissionProfiles_api)

            })
            .catch((err) => {
                toast.error(t("An error occurred while deleting profile") + err.message)
            })
    }

    const delete_profile_api = (profileId) => {
        return new Promise((resolve, reject) => {
            fetch(`/apiv2/delete_permission_profile/`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    "X-CSRFToken": getCookie("csrftoken"),

                },
                body: JSON.stringify({
                    profileId: profileId,
                }),
            })
                .then((data) => data.json())
                .then((data) => {
                    if (data.success) {
                        resolve(data)
                        getPermissionProfiles(getPermissionProfiles_api)

                    } else {
                        reject(new Error(t("Failed to update permission profile permissions")));
                    }
                })
        })
            .catch(error => {
                toast.error(t('Failed to update permission: ') + error.message);
            })
    }

    const filterPermissionsByCategories = (categories) => {
        const categorizedPermissions = new Set();
        const filteredPermissions = permissions.find(profile => profile.name === selectedProfile)?.all_permissions.filter(permission => {
            const isCategorized = categories.some(category => permission.description.toLowerCase().includes(category.toLowerCase()));
            if (isCategorized) {
                categorizedPermissions.add(permission.id);
            }
            return isCategorized;
        }) || [];
        return {filteredPermissions, categorizedPermissions};
    };

    // const filterPermissionsByCategories = (categories) => {
    //     return permissions.find(profile => profile.name === selectedProfile)?.all_permissions.filter(permission =>
    //         categories.some(category => permission.description.toLowerCase().includes(category.toLowerCase()))
    //     ) || [];
    // };
    //
    const getUncategorizedPermissions = (categorizedPermissions) => {
        return permissions.find(profile => profile.name === selectedProfile)?.all_permissions.filter(permission =>
            !categorizedPermissions.has(permission.id)
        ) || [];
    };

    return (
        <>
            {showAddNewUserModal && perm?.permissions?.includes("modify_permissions") &&<AddNewUserModal
                show={showAddNewUserModal}
                onHide={() => setShowAddNewUserModal(false)}
                theme={theme}
                user={user}
                selectedProfile={selectedProfile}
                getPermissionProfiles={getPermissionProfiles}
                getPermissionProfiles_api={getPermissionProfiles_api}
                allMembers={allMembers}
            />}
            {!showAddNewUserModal &&
                <div className="main-flex-container">
                    <div className="card main-flex-item" style={{overflow: "auto"}}>
                        <div className="flex-container">
                            {/*<h2>{t("Permission Profiles:  ")}</h2>*/}
                            <ComboBox
                                className="flex-item-input"
                                style={{
                                    width: "300px",
                                    margin: "10px",
                                    padding: "5px",
                                    verticalAlign: "middle",
                                }}
                                data={permissions.map(permission => permission.name)}
                                defaultValue={t("Select a permission profile")}
                                onChange={handleChangePermissionProfile}
                                label={selectedProfile ? (t("Select a permission profile")) : ""}
                            />

                            {perm?.permissions?.includes("modify_permissions") &&
                                <div
                                onClick={() => setShowAddProfile(true)}
                                className="flex-item-button">
                                <FaPlusCircle
                                    color={"#0d6dfb"}
                                    size={20}
                                    title={t("Add Permission Profile")}

                                    style={{
                                        cursor: "pointer",
                                        marginRight: "5%",

                                    }}
                                />

                                {t("Add Permission Profile")}
                            </div>}

                        </div>
                        {showAddProfile && perm?.permissions?.includes("modify_permissions") &&
                            <div>
                            <Form
                                onSubmit={handleSubmit}
                                render={(formRenderProps) => (

                                    <FormElement
                                        style={{
                                            maxWidth: 650,
                                            color: theme === "dark" ? "white" : "black",
                                        }}
                                    >
                                        <FieldWrapper>
                                            <fieldset>
                                                <div className="k-form-field-wrap"
                                                     style={{color: theme === "dark" ? "rgb(116,116,126)" : ""}}>
                                                    <Field
                                                        name={"profileName"}
                                                        component={Input}
                                                        labelClassName={"k-form-label"}
                                                        label={t("...New Profile Name with default permissions")}
                                                    />
                                                </div>
                                            </fieldset>
                                            <div className="k-form-buttons">
                                                <Button disabled={!formRenderProps.allowSubmit}>{t("Submit")}</Button>
                                            </div>

                                        </FieldWrapper>

                                    </FormElement>
                                )}
                            />
                        </div>}

                        {profileSelected && selectedProfile &&
                            <div style={{
                                border: "1px",
                                borderRadius: "5px",
                                padding: "20px",
                                marginTop: "20px",
                            }}>
                                <h1>{t(`Permissions for `)}: {selectedProfile ? selectedProfile : ""}</h1>
                                {perm.permissions.includes("modify_permissions") &&
                                    <Button
                                    size={"medium"}
                                    themeColor={theme === "dark" ? "primary" : "secondary"}
                                    fillMode={"solid"}
                                    rounded={"medium"}
                                    onClick={(event) => {
                                        handleDeleteProfile(event, selectedProfile)
                                    }}
                                >{t("Delete this Permission Profile")}
                                </Button>}
                                <ul style={{
                                    listStyleType: "none",
                                    margin: "20px",
                                    border: "1px solid rgba(124,116,200,0.1)",
                                    borderRadius: "5px",
                                    padding: "20px",

                                }}>
                                    <div style={{borderBottom: "thin solid rgba(124,116,200,0.5)",}}>

                                        <h2>{t("Members")}</h2>
                                        {filterPermissionsByCategories(["member", "note", "message","visit","group","family"]).filteredPermissions.map((permission, index) => (
                                            <div
                                                key={index}
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                <li
                                                    style={{
                                                        marginBottom: "10px",
                                                        padding: "10px",
                                                        backgroundColor: index % 2 === 0 ? "rgba(124,116,200,0.1)" : "rgba(124,116,200,0)",
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                        alignItems: "center",
                                                        width: "100%",
                                                    }}
                                                >{permission.description}
                                                    <Checkbox
                                                        style={{
                                                            marginLeft: "20px",
                                                        }}
                                                        value={permission.id}
                                                        disabled={!perm?.permissions?.includes("modify_permissions")}

                                                        checked={handleDefaultChecked(permission)}
                                                        onChange={(event) => handleCheckboxChange(event, permission.id)}
                                                    />
                                                </li>
                                            </div>
                                        ))}
                                    </div>
                                    <div style={{borderBottom: "thin solid rgba(124,116,200,0.5)",}}>

                                        <h2>{t("User Management")}</h2>
                                        {filterPermissionsByCategories(["user", "permission"]).filteredPermissions.map((permission, index) => (
                                            <div
                                                key={index}
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                <li
                                                    style={{
                                                        marginBottom: "10px",
                                                        padding: "10px",
                                                        backgroundColor: index % 2 === 0 ? "rgba(124,116,200,0.1)" : "rgba(124,116,200,0)",
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                        alignItems: "center",
                                                        width: "100%",
                                                    }}
                                                >{permission.description}
                                                    <Checkbox
                                                        style={{
                                                            marginLeft: "20px",
                                                        }}
                                                        value={permission.id}
                                                        disabled={!perm?.permissions?.includes("modify_permissions")}
                                                        checked={handleDefaultChecked(permission)}
                                                        onChange={(event) => handleCheckboxChange(event, permission.id)}
                                                    />
                                                </li>
                                            </div>
                                        ))}
                                    </div>
                                    <div style={{borderBottom: "thin solid rgba(124,116,200,0.5)",}}>
                                        <h2>{t("General Management")}</h2>
                                        {filterPermissionsByCategories(["event"]).filteredPermissions.map((permission, index) => (
                                            <div
                                                key={index}
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",

                                                }}
                                            >
                                                <li
                                                    style={{
                                                        marginBottom: "10px",
                                                        padding: "10px",
                                                        backgroundColor: index % 2 === 0 ? "rgba(124,116,200,0.1)" : "rgba(124,116,200,0)",
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                        alignItems: "center",
                                                        width: "100%",
                                                    }}
                                                >{permission.description}
                                                    <Checkbox
                                                        style={{
                                                            marginLeft: "20px",
                                                        }}
                                                        value={permission.id}
                                                        disabled={!perm?.permissions?.includes("modify_permissions")}
                                                        checked={handleDefaultChecked(permission)}
                                                        onChange={(event) => handleCheckboxChange(event, permission.id)}
                                                    />
                                                </li>
                                            </div>
                                        ))}</div>
                                    <div style={{borderBottom: "thin solid rgba(124,116,200,0.5)",}}>
                                        <h2>{t("Other")}</h2>
                                        {getUncategorizedPermissions(filterPermissionsByCategories(["member", "note", "message", "user", "permission", "event","visit","group","family"]).categorizedPermissions).map((permission, index) => (
                                            <div key={index} style={{display: "flex", justifyContent: "space-between"}}>
                                                <li style={{
                                                    marginBottom: "10px",
                                                    padding: "10px",
                                                    backgroundColor: index % 2 === 0 ? "rgba(124,116,200,0.1)" : "rgba(124,116,200,0)",
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                    width: "100%"
                                                }}>
                                                    {permission.description}
                                                    <Checkbox style={{marginLeft: "20px"}} value={permission.id}
                                                              disabled={!perm?.permissions?.includes("modify_permissions")}
                                                              checked={handleDefaultChecked(permission)}
                                                              onChange={(event) => handleCheckboxChange(event, permission.id)}/>
                                                </li>
                                            </div>
                                        ))}
                                    </div>

                                    {/*{permissions.find(profile => profile.name === selectedProfile)?.all_permissions.map((permission, index) => (*/}
                                    {/*    <div*/}
                                    {/*        style={{*/}
                                    {/*            display: "flex",*/}
                                    {/*            justifyContent: "space-between",*/}
                                    {/*        }}*/}
                                    {/*    >*/}
                                    {/*        <li key={index}*/}
                                    {/*            style={{*/}
                                    {/*                marginBottom: "10px",*/}
                                    {/*                padding: "10px",*/}
                                    {/*                backgroundColor: index % 2 === 0 ? "rgba(124,116,200,0.1)" : "rgba(124,116,200,0)",*/}
                                    {/*                display: "flex",*/}
                                    {/*                justifyContent: "space-between",*/}
                                    {/*                alignItems: "center",*/}
                                    {/*                width: "100%",*/}
                                    {/*            }}*/}
                                    {/*        >{permission.description}*/}
                                    {/*            <Checkbox*/}
                                    {/*                style={{*/}
                                    {/*                    marginLeft: "20px",*/}
                                    {/*                }}*/}
                                    {/*                value={permission.id}*/}
                                    {/*                checked={handleDefaultChecked(permission)}*/}
                                    {/*                onChange={(event) => handleCheckboxChange(event, permission.id)}*/}
                                    {/*            />*/}
                                    {/*        </li>*/}
                                    {/*    </div>*/}

                                    {/*))}*/}

                                </ul>

                            </div>}
                    </div>

                    {profileSelected &&
                        <div className="card main-flex-item" style={{overflow: "auto"}}>
                            <h1>{t("Users with Permissions for ")} {selectedProfile}</h1>

                            {perm?.permissions?.includes("modify_permissions") &&
                                <div className="card" style={{width: "100%", overflow: "auto"}}>
                                <div style={{
                                    margin: "10px",
                                    padding: "5px",
                                    display: "inline-flex",
                                    verticalAlign: "middle"
                                }}>
                                    <Button
                                        onClick={() => setShowAddNewUserModal(true)}

                                    >
                                        {t("Add a User with")} <strong>{selectedProfile}</strong> {t("profile")}
                                    </Button>
                                </div>
                            </div>}

                            <div>
                                <ul style={{listStyleType: "none"}}>

                                    {permissions.find(profile => profile.name === selectedProfile)?.permission_users.map((user, index) => (
                                        <li key={index} style={{
                                            margin: "10px",
                                            padding: "10px",
                                            backgroundColor: index % 2 === 0 ? "rgba(124,116,200,0.1)" : "rgba(124,116,200,0)",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                        }}>
                                            <div style={{display: "flex", alignItems: "center", flexGrow: 1}}>
                                                <span
                                                    style={{marginRight: "auto"}}>{user.username} {user.first_name} {user.last_name}</span>
                                                <p style={{
                                                    margin: "10px",
                                                    padding: "20px"
                                                }}>{user.is_active ? t("Active") : t("Not Active")}</p>
                                                <Switch
                                                    defaultChecked={user.is_active}
                                                    disabled={!perm?.permissions?.includes("modify_permissions")}
                                                    onLabel={t("Active")}
                                                    offLabel={t("Not Active")}
                                                    onChange={(event) => handleUserActiveChange(event, user.id)}
                                                />
                                            </div>
                                            {perm?.permissions?.includes("modify_permissions") &&
                                                <FaTrash
                                                onClick={(event) => handleDeleteUser(event, user.id)}
                                                color={"#fd6358"}
                                                size={20}
                                                title={`${t("Remove User from")} ${selectedProfile}`}
                                                style={{
                                                    cursor: "pointer",
                                                    marginLeft: "5%",
                                                }}
                                            />}
                                        </li>
                                    ))}

                                </ul>
                            </div>
                        </div>

                    }
                </div>}

        </>

    );

};


export default PermissionProfiles;

