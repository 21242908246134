import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import Modal from 'react-bootstrap/Modal';
import {FaSave, FaTimes, FaUserPlus} from "react-icons/fa";
import {getCookie} from "../func/Func";
import toast from "react-hot-toast";
import {ConfirmModal} from "./ConfirmModal";
import {FamilyMemberModal} from "./FamilyMemberModal";
import {FaEnvelope} from "react-icons/fa6";

export const MessagesSent = (props) => {
    const {t} = useTranslation();
    const {
        show,
        onShow,
        onHide,
        selectedbirthdayid,
    } = props;

    const [members, setMembers] = useState([]);
    const [messagesSent, setMessagesSent] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);


    useEffect(() => {
        if (selectedbirthdayid) {
            getMessagesSent();
        }
    }, [selectedbirthdayid]);

    const getMessagesSent_api = () => {
        return new Promise((resolve, reject) => {
            fetch(`/apiv2/get_messages_sent/${selectedbirthdayid}`, {})
                .then(res => res.json())
                .then((data) => {
                    console.log("data: ", data)
                    if (data.success) {
                        resolve(data.data)
                    } else {
                        reject(data)
                    }
                })
                .catch(err => {
                    reject(err);
                })
        })
    }
    const getMessagesSent = () => {
        getMessagesSent_api(selectedbirthdayid)
            .then((data) => {
                setMessagesSent(data)
                setIsLoaded(true)
                // console.log("id", selectedbirthdayid)

            })
            .catch((err) => {
                toast.error(err)
            })
    }

    const onModalHide = () => {
        onHide();
    }


    return (
        <>

            <Modal
                size={'md'}
                show={show}
                onHide={onHide}
                centered={true}
                className={"modal-blur w-100 scrollable"}
                backdrop={"static"}

            >
                <Modal.Body>
                    <div className="row scrollable">
                        <div className="d-flex w-100">
                            <div>
                                <h2>{t('Previous Messages Sent')}</h2>
                                <span>
                                    <div className="table-responsive">
                                        {messagesSent?.length > 0 ?
                                            <table style={{width: '100%', borderCollapse: 'collapse', overflow: 'auto'}}>
                                                <tr>
                                                    <th style={{textAlign: 'left', padding: '8px'}}>{t('Message ID')}</th>
                                                    <th style={{textAlign: 'left', padding: '8px'}}>{t('Subject')}</th>
                                                    <th style={{textAlign: 'left', padding: '8px'}}>{t('Sent By')}</th>
                                                    <th style={{textAlign: 'left', padding: '8px'}}>{t('Date Sent')}</th>
                                                    <th style={{textAlign: 'left', }}>{t('Message')}</th>
                                                </tr>

                                                {messagesSent.map((msg, index) => {
                                                    return (
                                                        <tr key={index} style={{backgroundColor: index % 2 === 0 ? 'rgba(56,73,94,0.71)' : '', justifyContent: "space-between"}}>
                                                            <td style={{textAlign: 'left', padding: '8px'}}>{msg.id}</td>
                                                            <td style={{textAlign: 'left', padding: '8px'}}>{msg.subject}</td>
                                                            <td style={{textAlign: 'left', padding: '8px'}}>{msg.from_name}</td>
                                                            <td style={{textAlign: 'left', padding: '8px'}}>
                                                                {new Date(msg.dt_created).toLocaleString("en-GB", {
                                                                    year: "numeric",
                                                                    month: "2-digit",
                                                                    day: "2-digit",
                                                                    hour: "2-digit",
                                                                    minute: "2-digit",
                                                                    hour12: false,
                                                                })}
                                                            </td>
                                                            <td style={{textAlign: 'left'}}>{msg.message_data}</td>


                                                        </tr>
                                                    )
                                                })}
                                            </table> : <h5>{t('No Messages sent yet')}</h5>}
                                    </div>
                                </span>
                            </div>

                            <FaTimes size={20} className={"ms-auto cursor-pointer"} onClick={onModalHide}/>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}